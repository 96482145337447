import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { Timestamp } from "firebase/firestore";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Stack
} from "react-bootstrap";
import { roundToDollars } from '../utils/MoneyUtils';
import {
  Event, getEvent, documentToEvent, saveEvent, recordHistory,
  loadHistoryMap
} from '../data/EventData';
import { getProductMap } from '../data/ProductData';
import { ProductForm } from './ProductForm';
import { RecipeForm } from './RecipeForm';

export class EventForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productMap: new Map(),
      event: new Event(),
      validated: false,
      isOpen: false,
      showForm: false,
      modalString: "",
      chosenProduct: "",
      chosenMadeQuantity: "",
      chosenSoldQuantity: "",
      chosenSoldPrice: 0,
      deleteDocRef: "",
      deleteDocDescription: "",
      isDeleteProductConfirmOpen: false,
      historyMap: new Map(),
      dataLoading: true,
      showProductRef: "",
      showRecipeRef: "",
      showProductForm: "",
      showRecipeForm: "",
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeChosenProductValue = this.changeChosenProductValue.bind(this);
    this.handleClose = this.handleClose(this);
  }

  handleClose = () => this.setState({ isOpen: false });

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  }
  toggleShowProductForm = () => {
    this.setState({
      showProductForm: !this.state.showProductForm,
    });
  }

  toggleShowRecipeForm = () => {
    this.setState({
      showRecipeForm: !this.state.showRecipeForm,
    });
  }

  addProduct = () => {
    /**
    * First remove the product if it exists.
    */

    let cleanedProducts = this.state.event.products.filter(product => !product.startsWith(this.state.chosenProduct));
    cleanedProducts.push(this.state.chosenProduct + ':' + this.state.chosenMadeQuantity + ":" + this.state.chosenSoldQuantity + ":" + this.state.chosenSoldPrice);

    let tempEvent = this.state.event;
    tempEvent.products = cleanedProducts;
    this.setState({
      showForm: !this.state.showForm,
      chosenProduct: "",
      chosenMadeQuantity: "",
      chosenSoldQuantity: "",
      chosenSoldPrice: 0,
      event: tempEvent,
    });
  }

  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      deleteDocDescription: description,
      isDeleteProductConfirmOpen: !this.state.isDeleteProductConfirmOpen,
    });
  }

  handleDelete = async (docRef) => {
    let cleanedProducts = new Array();
    cleanedProducts = this.state.event.products.filter(product => product.indexOf(docRef) === -1);
    let tempEvent = this.state.event;
    tempEvent.products = cleanedProducts;
    this.setState({
      deleteDocRef: "",
      chosenProduct: "",
      chosenMadeQuantity: "",
      chosenSoldQuantity: "",
      chosenSoldPrice: 0,
      isDeleteProductConfirmOpen: false,
      event: tempEvent,
    });
  }
  async getExistingEvent() {

    try {
      let existingDoc = await getEvent(this.props.docRef);
      let existingEvent = documentToEvent(existingDoc);
      let newHistoryMap = new Map();

      if (existingEvent.history && existingEvent.history.length !== 0) {
        newHistoryMap = await loadHistoryMap(existingEvent.history);
      }

      this.setState({
        event: existingEvent,
        historyMap: newHistoryMap,
      });
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }
  }

  makeProductsMap() {
    let map = new Map();
    if (!this.state.event.products || this.state.event.products.size === 0) {
      return map;
    }
    this.state.event.products.forEach(currentProductQuantity => {
      let productParts = currentProductQuantity.split(":");
      let productRef = productParts[0];
      let madeQuantity = productParts[1];
      let soldQuantity = productParts[2];
      let soldPrice = productParts[3];
      let productDoc = this.state.productMap.get(productRef);
      let recipeDoc = this.props.recipeMap.get(productDoc.recipe);
      if (productDoc && productDoc !== '') {
        map.set(productRef, {
          "name": productDoc.name,
          "quantity_made": madeQuantity,
          "quantity_sold": soldQuantity,
          "sold_price": soldPrice,
          "cost_high": recipeDoc.cost_per_item_high * productDoc.recipe_qty,
          "recipe": recipeDoc.name,
          "recipe_makes": recipeDoc.makes_qty,
          "price": productDoc.price,
          "recipe_qty": productDoc.recipe_qty,
          "ingredient_label_file": productDoc.ingredient_label_file,
          "name_label_file": productDoc.name_label_file,
        });
      }
    });
    return map;
  }

  async loadData() {
    let productMap = await getProductMap();

    this.setState({
      productMap: productMap,
    }, () => {
      if (this.props.docRef !== '') {
        this.getExistingEvent();
      }
    });
  }

  componentDidMount() {
    this.loadData();
  }

  changeChosenProductValue(event) {
    if (event.target.id === 'products') {
      this.setState({ chosenProduct: event.target.value });
    } else if (event.target.id === 'made-quantity') {
      this.setState({ chosenMadeQuantity: event.target.value });
    } else if (event.target.id === 'sold-quantity') {
      this.setState({ chosenSoldQuantity: event.target.value });
    } else if (event.target.id === 'sold-price') {
      this.setState({ chosenSoldPrice: event.target.value });
    }
  }

  changeValue(event) {
    this.props.setDataChanged(true);
    if (event.target.id === 'name') {
      let tempEvent = this.state.event;
      tempEvent.name = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'description') {
      let tempEvent = this.state.event;
      tempEvent.description = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'notes') {
      let tempEvent = this.state.event;
      tempEvent.notes = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'location') {
      let tempEvent = this.state.event;
      tempEvent.location = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'profit') {
      let tempEvent = this.state.event;
      tempEvent.profit = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'date') {
      let tempEvent = this.state.event;
      tempEvent.date = event.target.value;
      this.setState({ event: tempEvent });
    } else if (event.target.id === 'time') {
      let tempEvent = this.state.event;
      tempEvent.time = event.target.value;
      this.setState({ event: tempEvent });
    }
  }

  saveChangedEvent = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    let documentName = "";
    if (this.props.docRef === '') {
      const regex2 = /[^A-Za-z0-9_]/ig;
      documentName = this.state.event.name.replaceAll(regex2, '_');
    } else {
      documentName = this.props.docRef;
    }

    let newHistoryList = new Array();
    let newHistoryMap = new Map();
    let now = Timestamp.now();

    if (this.props.mode === 'Edit') {
      if (this.state.event.history && this.state.event.history.length !== 0) {
        newHistoryList = this.state.event.history;
      }
      await recordHistory(newHistoryList, now, this.state.event, documentName);
    }

    let docData = {
      name: this.state.event.name,
      description: (this.state.event.description ? this.state.event.description : ""),
      products: this.state.event.products,
      notes: (this.state.event.notes ? this.state.event.notes : ""),
      location: (this.state.event.location ? this.state.event.location : ""),
      profit: (this.state.event.profit ? this.state.event.profit : 0),
      date: (this.state.event.date ? this.state.event.date : ""),
      time: (this.state.event.time ? this.state.event.time : ""),
      last_update: now,
      history: newHistoryList,
    };

    saveEvent(documentName, docData);

    this.setState({
      event: new Event(),
      validated: false,
      isOpen: false,
      modalString: "Event added!",
      deleteDocRef: "",
      deleteDocDescription: "",
      historyMap: newHistoryMap,
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.setDataChanged(false);
    this.props.toggleForm();
  }

  renderHistoryTableRow = (historyDocRef, historyRecord) => {
    let updatedDate = historyRecord.updateDate.toDate().toString();
    let oldValue = historyRecord.oldValue;
    let newValue = historyRecord.newValue;
    if (historyRecord.field === 'products') {
      let oldValueString = '';
      for (let ii = 0; ii < oldValue.length; ii++) {
        oldValueString = oldValueString + ' ' + oldValue[ii];
      }
      oldValue = oldValueString;

      let newValueString = '';
      for (let ii = 0; ii < newValue.length; ii++) {
        newValueString = newValueString + ' ' + newValue[ii];
      }
      newValue = newValueString;
    }
    return (
      <tr>
        <td className='history_cell'>{updatedDate}</td>
        <td className='history_cell'> {historyRecord.field}</td>
        <td className='history_cell'>{oldValue}</td>
        <td className='history_cell'>{newValue}</td>
      </tr>
    );
  }

  renderProductTableRow = (docRef, doc) => {
    let ingredientLabelFile = "";
    if (doc.ingredient_label_file) {
      ingredientLabelFile =
        "https://drive.google.com/uc?export=view&id=" +
        doc.ingredient_label_file;
    }
    let nameLabelFile = "";
    if (doc.name_label_file) {
      nameLabelFile =
        "https://drive.google.com/uc?export=view&id=" + doc.name_label_file;
    }
    let name = doc.name;

    return (
      <tr>
        <td>{doc.name}</td>
        <td>{doc.quantity_made}</td>
        <td>{doc.quantity_sold}</td>
        <td>{roundToDollars(doc.sold_price)}</td>
        <td>{roundToDollars(doc.cost_high)}</td>
        <td>{roundToDollars(doc.price)}</td>
        <td>{doc.recipe}</td>
        <td>{doc.recipe_qty}</td>
        <td>{doc.recipe_makes}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            <DropdownButton variant="dark" id="dropdown-basic-button" title="Actions">
              <Dropdown.Item variant="dark" onClick={() => {
                this.setState({
                  chosenProduct: docRef,
                  chosenMadeQuantity: doc.quantity_made,
                  chosenSoldQuantity: doc.quantity_sold,
                  chosenSoldPrice: doc.sold_price,
                }, () => {
                  this.toggleShowForm();
                });
              }}>Edit</Dropdown.Item>
              <Dropdown.Item variant="dark" onClick={() => { this.toggleDeleteModal(docRef, name) }}>Delete</Dropdown.Item>
              <Dropdown.Item variant="dark" onClick={() => {
                this.setState({
                  showProductRef: docRef,
                }, () => {
                  this.toggleShowProductForm();
                });
              }}>View Product</Dropdown.Item>
              <Dropdown.Item variant="dark" onClick={() => {
                this.setState({
                  showRecipeRef: docRef,
                }, () => {
                  this.toggleShowRecipeForm();
                });
              }}>
                View Recipe</Dropdown.Item>
              {doc.ingredient_label_file && (
                <Dropdown.Item variant="dark" href={ingredientLabelFile} download={ingredientLabelFile}>Ingredient & Disclaimer Labels</Dropdown.Item>
              )}
              {doc.name_label_file && (
                <Dropdown.Item variant="dark" href={nameLabelFile} download={nameLabelFile}>Name Labels</Dropdown.Item>
              )}
            </DropdownButton>
          </Stack>
        </td>
      </tr>
    );

  }

  getProductSelect = (docRef, doc) => {
    return (
      <>
        <option value={docRef}>{doc.name} -Our Price:{doc.price}</option>
      </>
    );
  }

  render() {
    let productsMap = this.makeProductsMap();
    let historyMap = this.state.historyMap;
    let lastUpdatedString = "";
    if (this.state.event.last_update) {
      lastUpdatedString = this.state.event.last_update.toDate().toString();
    }

    return (
      <>
        {
          /* 
          * Event 
          */
        }
        <Container fluid>
          {/*
          ------------- start Modal add Products ---------------
           */}
          <Modal show={this.state.showForm} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.saveChangedEvent}>
                <Form.Group as={Col}>
                  <Form.Label as={Col} htmlFor="products">Product:</Form.Label>
                  <Form.Select
                    required
                    value={this.state.chosenProduct}
                    id="products"
                    aria-label="Default select example"
                    onChange={this.changeChosenProductValue}
                  >
                    <option></option>
                    {
                      [...this.state.productMap].map(([key, value]) => {
                        return this.getProductSelect(key, value);
                      }
                      )
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Label as={Col} htmlFor="made-quantity">Made Quantity:</Form.Label>
                <Form.Group as={Col} className="mb-3">
                  <Form.Control
                    value={this.state.chosenMadeQuantity}
                    required
                    placeholder="0"
                    id="made-quantity"
                    onChange={this.changeChosenProductValue}
                  />
                </Form.Group>
                <Form.Label as={Col} htmlFor="sold-price">Sold Price:</Form.Label>
                <Form.Group as={Col} className="mb-3">
                  <Form.Control
                    value={this.state.chosenSoldPrice}
                    required
                    placeholder="0"
                    id="sold-price"
                    onChange={this.changeChosenProductValue}
                  />
                </Form.Group>
                <Form.Label as={Col} htmlFor="sold-quantity">Sold Quantity:</Form.Label>
                <Form.Group as={Col} className="mb-3">
                  <Form.Control
                    value={this.state.chosenSoldQuantity}
                    required
                    placeholder="0"
                    id="sold-quantity"
                    onChange={this.changeChosenProductValue}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={this.toggleShowForm}>
                Cancel
              </Button>{' '}
              <Button variant="secondary" size="sm" onClick={this.addProduct}>
                Save Product
              </Button>
            </Modal.Footer>
          </Modal>
          {/*
          ------------- end Modal add product ---------------
           */}
          <Modal size="lg" show={this.state.showProductForm} onHide={this.handleClose}>
            <ProductForm
              recipeMap={this.props.recipeMap}
              docRef={this.state.showProductRef}
              mode={"View"}
              toggleForm={() => {
                this.toggleShowProductForm();
              }}
            />
          </Modal>
          <Modal size="lg" show={this.state.showRecipeForm} onHide={this.handleClose}>
            <RecipeForm
              docRef={this.state.showRecipeRef}
              mode={"View"}
              toggleForm={() => {
                this.toggleShowRecipeForm();
              }}
            />
          </Modal>
          {/*
          ------------- start Modal delete packaging ---------------
           */}
          <Modal show={this.state.isDeleteProductConfirmOpen}>
            <div>
              Delete {this.state.deleteDocDescription}?
            </div>
            <Container>
              <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
              <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
            </Container>
          </Modal>
          {/*
          ------------- end Modal delete packaging ---------------
           */}
          <h1>Event {this.props.mode} "{this.state.event.name}"</h1>
          <Form noValidate validated={this.state.validated} onSubmit={this.saveChangedEvent}>
            <Button
              size="sm"
              variant="dark"
              onClick={() => { this.props.toggleForm() }}>
              Cancel
            </Button>{' '}
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              type="submit">
              Save
            </Button>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name">Name:</Form.Label>
                  <Form.Control
                    disabled={this.props.mode === 'View'}
                    required
                    value={this.state.event.name}
                    placeholder="Name"
                    id="name"
                    onChange={this.changeValue}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name">Location:</Form.Label>
                  <Form.Control
                    disabled={this.props.mode === 'View'}
                    value={this.state.event.location}
                    placeholder="Location"
                    id="location"
                    onChange={this.changeValue}
                  />
                </Form.Group>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label as={Col} htmlFor="description">Description:</Form.Label>
                    <Form.Control
                      as="textarea"
                      disabled={this.props.mode === 'View'}
                      value={this.state.event.description}
                      placeholder="description"
                      id="description"
                      onChange={this.changeValue}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label as={Col} htmlFor="notes">Notes:</Form.Label>
                    <Form.Control
                      as="textarea"
                      disabled={this.props.mode === 'View'}
                      value={this.state.event.notes}
                      placeholder="notes"
                      id="notes"
                      onChange={this.changeValue}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="date">Date:</Form.Label>
              <Form.Label as={Col} htmlFor="time">Time:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.event.date}
                  placeholder="0"
                  id="date"
                  type="date"
                  onChange={this.changeValue}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  value={this.state.event.time}
                  placeholder="0"
                  id="time"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="profit">Profit:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.event.profit}
                  placeholder="0"
                  id="profit"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              Products:
            </Row>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenProduct: "",
                  chosenMadeQuantity: "",
                  chosenSoldQuantity: "",
                  chosenSoldPrice: 0,
                }, () => {
                  this.toggleShowForm()
                });
              }}>
              Add Product
            </Button>
            <Row className="mb-3">
              <Table bordered>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity Made</th>
                    <th>Quantity Sold</th>
                    <th>Sold Price</th>
                    <th>Product Cost High</th>
                    <th>Our Product Price</th>
                    <th>Recipe</th>
                    <th>Recipe Quantity per Product</th>
                    <th>Recipe Makes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...productsMap].map(([key, value]) => {
                      return this.renderProductTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
            <Row>
              <Container>
                <Button
                  size="sm"
                  variant="dark"
                  onClick={() => { this.props.toggleForm() }}>
                  Cancel
                </Button>{' '}
                <Button
                  size="sm"
                  disabled={this.props.mode === 'View'}
                  variant="dark"
                  type="submit">
                  Save
                </Button>
              </Container>
            </Row>
            <Row className="mb-3">
              <Form.Label htmlFor="history">History:</Form.Label>
              <Table
                disabled
                id="history"
                bordered>
                <thead>
                  <tr>
                    <th>Update Date</th>
                    <th>Field</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...historyMap].map(([key, value]) => {
                      return this.renderHistoryTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
          </Form>
        </Container >
      </>
    );

  }
}

import { query, collection, orderBy, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { History } from "./History";
import { database } from '../firebase-config';
const COLLECTION = "ingredients";


export class Ingredient {
  description = ""
  cost = ""
  per = ""
  total_price = ""
  total_quantity = ""
  information = ""
  last_updated = ""
  history = new Array()
}

export const getIngredientMapFromDB = async () => {
    const  ingredientMap = new Map();
    try {
      const docQuery = query(collection(database, "ingredients"), orderBy("description", "asc"));
      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        ingredientMap.set(doc.id, doc.data());
      });
      return ingredientMap;
    } catch (e) {
      console.log("Error getting ingredients:", e);
      return new Map();
    }
  }

  export const getIngredient = async(documentRef) => {
    try {
      const existingDocRef = doc(database, COLLECTION, documentRef);
      const existingDoc = await getDoc(existingDocRef);
      return existingDoc;
    } catch (e) {
      console.log("getIngredient(): Error getting Recipe ref:" + documentRef);
    }
  }

  export function documentToIngredient(document) {
    let ingredient = new Ingredient();
    ingredient.document_reference = document.id;   
    ingredient.description = document.data().description;
    ingredient.cost = document.data().cost;
    ingredient.per = document.data().per;
    ingredient.total_price = document.data().total_price;
    ingredient.total_quantity = document.data().total_quantity;
    ingredient.information = document.data().information;
    ingredient.last_updated = document.data().last_updated;
    ingredient.history = document.data().history;
    
    return ingredient;
  }

  export const saveIngredient = async (docRef,ingredientData) => {
    try {
      await setDoc(doc(database, COLLECTION, docRef), ingredientData, { merge: true });
    } catch (e) {
      console.log("Error saving Ingredient:", e);
    }
  }

  /**
   * ------------ Start History ----------------
   */
  export const  getHistoryRecord= async(history_key) => {
    let existingHistoryRef = doc(database, "history", history_key);
    let existingHistory = await getDoc(existingHistoryRef);
    let historyRecord = new History();
    historyRecord.key = history_key;
    historyRecord.updateDate = existingHistory.data().last_updated;
    historyRecord.field = existingHistory.data().field;
    historyRecord.oldValue = existingHistory.data().oldValue;
    historyRecord.newValue = existingHistory.data().newValue;
    return historyRecord;
  }

  export const loadHistoryMap = async(historyList) => {
    let map = new Map();
    for (const history_key of historyList) {
      try {
        let historyRecord = await getHistoryRecord(history_key);
        map.set(history_key, historyRecord);
      } catch (e) {
        console.log("Error getting history ref:" + history_key);
      }
    }
    return map;
  }

  export function addHistory(newHistoryList, now, ingredientName, field, oldValue, newValue) {
    let docData = {
      last_updated: now,
      field: field,
      oldValue: oldValue,
      newValue: newValue,
    };

    let documentName = 'ingredient_' + ingredientName + '_' + field + "_" + now;

    try {
      setDoc(doc(database, "history", documentName), docData);
    } catch (e) {
      console.log("Error saving History:", e);
      return;
    }

    newHistoryList.push(documentName);
  }

  export const recordHistory = async(newHistoryList, now, newIngredient, docRef) => {
    let existingDoc = "";
    try {
      let oldDocRef = doc(database, COLLECTION, docRef);
      existingDoc = await getDoc(oldDocRef);
    } catch (e) {
      console.log("Error getting existing Ingredient for History:", e);
    }

    if (newIngredient.description !== existingDoc.data().description) {
      addHistory(newHistoryList, now, docRef, "description", existingDoc.data().description, newIngredient.description);
    }
    if (newIngredient.cost !== existingDoc.data().cost) {
      addHistory(newHistoryList, now, docRef, "cost", existingDoc.data().cost, newIngredient.cost);
    }
    if (newIngredient.per !== existingDoc.data().per) {
      addHistory(newHistoryList, now, docRef, "per", existingDoc.data().per, newIngredient.per);
    }
    if (newIngredient.total_price !== existingDoc.data().total_price) {
      addHistory(newHistoryList, now, docRef, "Total Price", existingDoc.data().total_price, newIngredient.total_price);
    }
    if (newIngredient.total_quantity !== existingDoc.data().total_quantity) {
      addHistory(newHistoryList, now, docRef, "Total Quantity", existingDoc.data().total_quantity, newIngredient.total_quantity);
    }
    if (newIngredient.information !== existingDoc.data().information) {
      addHistory(newHistoryList, now, docRef, "Information", existingDoc.data().information, newIngredient.information);
    }
  }
  /**
   * ------------ End History ----------------
   */

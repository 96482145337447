import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {
  BrowserRouter
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import './fonts/Bakerie/BakerieSmooth-Black.otf';
import { Navigation } from './ui/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';


class ElevatedBakingDashboardApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        isSignedIn: false
    }
  }

  render() {

    return (
      <>
      <div>
        {/*
            * Main section
            */}
        <BrowserRouter>
          <Navigation/>
        </BrowserRouter>
      </div>
      </>
    );
  }
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Helmet>
      <title>Elevated Baking Dashboard</title>
      <link rel="icon" href="../src/images/rolling_pin.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto+Condensed&family=Unite&display=swap" rel="stylesheet" />
   </Helmet>
    <ElevatedBakingDashboardApp
    />
  </React.StrictMode >,
  document.getElementById('root')
);
import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { Timestamp } from "firebase/firestore";

import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Stack
} from "react-bootstrap";
import { roundToDollars } from '../utils/MoneyUtils';
import {
  Product, getProduct, documentToProduct, saveProduct, recalculateCosts, recordHistory,
  loadHistoryMap, productNameExists
} from '../data/ProductData';
import { getPackagingMap } from '../data/PackagingData';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


export class ProductForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      packagingMap: new Map(),
      recipeDoc: "",
      product: new Product(),
      validated: false,
      isOpen: false,
      showForm: false,
      modalString: "",
      chosenPackaging: "",
      chosenQuantity: "",
      deleteDocRef: "",
      deleteDocDescription: "",
      isDeletePackagingConfirmOpen: false,
      historyMap: new Map(),
      dataLoading: true,
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeChosenPackingValue = this.changeChosenPackingValue.bind(this);
    this.handleClose = this.handleClose(this);
  }

  handleClose = () => this.setState({ isOpen: false });

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  toggleShowProductForm = () => {
    this.setState({
      showProdcutForm: !this.state.showProductForm,
    });
  }

  addPackaging = () => {
    /**
    * First remove the packaging if it exists.
    */
    let cleanedPackaging = this.state.product.packaging.filter(packaging => !packaging.startsWith(this.state.chosenPackaging));
    cleanedPackaging.push(this.state.chosenPackaging + ":" + this.state.chosenQuantity);

    let tempProduct = this.state.product;
    tempProduct.packaging = cleanedPackaging;
    this.setState({
      showForm: !this.state.showForm,
      product: tempProduct,
    }, () => {
      let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
      this.setState({
        product: tempProduct,
      });
    });

  }

  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      deleteDocDescription: description,
      isDeletePackagingConfirmOpen: !this.state.isDeletePackagingConfirmOpen,
    });
  }

  handleDelete = async (docRef) => {
    let cleanedPackaging = new Array();
    cleanedPackaging = this.state.product.packaging.filter(packaging => packaging.indexOf(docRef) === -1);
    let tempProduct = this.state.product;
    tempProduct.packaging = cleanedPackaging;
    this.setState({
      deleteDocRef: "",
      isDeletePackagingConfirmOpen: false,
      product: tempProduct,
    }, () => {
      let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
      this.setState({
        product: tempProduct,
      });
    });
  }
  async getExistingProduct() {

    try {
      let existingDoc = await getProduct(this.props.docRef);
      let existingProduct = documentToProduct(existingDoc);
      let newHistoryMap = new Map();

      if (this.props.mode === 'Copy') {
        existingProduct.history = new Array();
      } else {
        if (existingProduct.history && existingProduct.history.length !== 0) {
          newHistoryMap = await loadHistoryMap(existingProduct.history);
        }
      }

      this.setState({
        product: existingProduct,
        historyMap: newHistoryMap,
      }, () => {
        let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
        this.setState({
          product: tempProduct,
        });
      });
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }
  }

  makePackagingMap() {
    let map = new Map();
    if (!this.state.product.packaging || this.state.product.packaging.size === 0) {
      return map;
    }
    this.state.product.packaging.forEach(packagingRefQuantity => {
      let packagingParts = packagingRefQuantity.split(":");
      let packagingRef = packagingParts[0];
      let quantity = packagingParts[1];

      let packagingDoc = this.state.packagingMap.get(packagingRef)
      if (packagingDoc && packagingDoc !== '') {
        map.set(packagingRef, {
          "description": packagingDoc.description,
          "cost": packagingDoc.cost,
          "per": packagingDoc.per,
          "quantity": quantity,
          "total_cost": (packagingDoc.cost * quantity),
        });
      }
    });
    return map;
  }

  getTotalPackagingCost(packaging) {
    let totalPackaging = 0.0;
    if (!packaging || packaging.size === 0) {
      return totalPackaging;
    }
    packaging.forEach(currentPackaging => {
      let packagingDoc = this.state.packagingMap.get(currentPackaging);
      totalPackaging = totalPackaging + packagingDoc.cost;
    })
    return totalPackaging;
  }

  getNewProductReference(name) {
    const regex2 = /[^A-Za-z0-9_]/ig;
    return name.replaceAll(regex2, '_');
  }

  async loadData() {
    let packagingMap = await getPackagingMap();

    this.setState({
      packagingMap: packagingMap,
    }, () => {
      if (this.props.docRef !== '') {
        this.getExistingProduct();
      }
    });

  }

  componentDidMount() {
    this.loadData();
  }

  changeChosenPackingValue(event) {
    if (event.target.id === 'packaging') {
      this.setState({ chosenPackaging: event.target.value });
    } else if (event.target.id === 'quantity') {
      this.setState({ chosenQuantity: event.target.value });
    }
  }

  changeValue(event) {
    if (event.target.id === 'name') {
      let tempProduct = this.state.product;
      tempProduct.name = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'price') {
      let tempProduct = this.state.product;
      tempProduct.price = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'recipe') {
      let tempProduct = this.state.product;
      tempProduct.recipe = event.target.value;
      this.setState({ product: tempProduct }, () => {
        let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
        this.setState({
          product: tempProduct,
        });
      });
    } else if (event.target.id === 'recipe_qty') {
      let tempProduct = this.state.product;
      tempProduct.recipe_qty = event.target.value;
      this.setState({ product: tempProduct }, () => {
        let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
        this.setState({
          product: tempProduct,
        });
      });
    } else if (event.target.id === 'ingredient_label_file') {
      let tempProduct = this.state.product;
      tempProduct.ingredient_label_file = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'name_label_file') {
      let tempProduct = this.state.product;
      tempProduct.name_label_file = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'google_image_file_id') {
      let tempProduct = this.state.product;
      tempProduct.google_image_file_id = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'description') {
      let tempProduct = this.state.product;
      tempProduct.description = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'category') {
      let tempProduct = this.state.product;
      tempProduct.category = event.target.value;
      this.setState({ product: tempProduct });
    } else if (event.target.id === 'on_website') {
      let tempProduct = this.state.product;
      tempProduct.on_website = !tempProduct.on_website;
      this.setState({ product: tempProduct });
    }
  }

  saveChangedProduct = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let documentReference = "";
    if (this.state.product.name !== '' && this.props.mode === 'Copy') {
      documentReference = this.getNewProductReference(this.state.product.name);
      let nameElement = document.getElementById('name');
      nameElement.setCustomValidity('');
      let exists = await productNameExists(documentReference);
      if (exists) {
        nameElement.setCustomValidity("A Product with this name already exists");
        //nameElement.reportValidity();
      }
    } else {
      documentReference = this.state.product.document_reference;
    }
    
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      form.reportValidity();
      return;
    }

    let tempProduct = recalculateCosts(this.state.product, this.props.recipeMap, this.state.packagingMap);
    this.setState({
      product: tempProduct,
    });

    let newHistoryList = new Array();
    let newHistoryMap = new Map();
    let tempLastUpdated = "";

    if (this.props.mode === 'Edit') {
      tempLastUpdated = Timestamp.now();
      if (this.state.product.history && this.state.product.history.length !== 0) {
        newHistoryList = this.state.product.history;
      }
      await recordHistory(newHistoryList, tempLastUpdated, this.state.product, documentReference);
    }

    let ingredient_label_file = "";
    if (this.state.product.ingredient_label_file) {
      ingredient_label_file = this.state.product.ingredient_label_file;
    }
    let name_label_file = "";
    if (this.state.product.name_label_file) {
      name_label_file = this.state.product.name_label_file;
    }

    let google_image_file_id = "";
    if (this.state.product.google_image_file_id) {
      google_image_file_id = this.state.product.google_image_file_id;
    }

    let description = "";
    if (this.state.product.description) {
      description = this.state.product.description;
    }

    let docData = {
      name: this.state.product.name,
      description: description,
      category: this.state.product.category,
      packaging: this.state.product.packaging,
      ingredient_label_file: ingredient_label_file,
      name_label_file: name_label_file,
      google_image_file_id: google_image_file_id,
      packaging_cost: this.state.product.packaging_cost,
      price: this.state.product.price,
      recipe: this.state.product.recipe,
      recipe_qty: this.state.product.recipe_qty,
      on_website: this.state.product.on_website,
      last_update: tempLastUpdated,
      history: newHistoryList,
    };

    saveProduct(documentReference, docData);

    this.setState({
      product: new Product(),
      validated: false,
      isOpen: true,
      modalString: "Recipe added!",
      deleteDocRef: "",
      deleteDocDescription: "",
      historyMap: newHistoryMap,
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  renderHistoryTableRow = (historyDocRef, historyRecord) => {
    let updatedDate = historyRecord.updateDate.toDate().toString();
    let oldValue = historyRecord.oldValue;
    let newValue = historyRecord.newValue;
    
    if(historyRecord.field === 'packaging') {
      let oldValueString = '';
      for (let ii = 0; ii < oldValue.length; ii++) {
        let parts = oldValue[ii].split(":");
        let packaging = parts[0].trim();
        let quantity = parts[1].trim();
        oldValueString = oldValueString + '* ' + packaging + " - qty: " + quantity + '\n';
      }
      oldValue = oldValueString;
   
      let newValueString = '';
      for (let ii = 0; ii < newValue.length; ii++) {
        let parts = newValue[ii].split(":");
        let packaging = parts[0];
        let quantity = parts[1];
        newValueString = newValueString + '* ' + packaging + " - qty: " + quantity + '\n';
      }
      newValue = newValueString;
    }

    return (
      <tr>
        <td>{updatedDate}</td>
        <td>{historyRecord.field}</td>
        <td><div className="scrollable-cell">{historyRecord.oldValue}</div></td>
        <td>{historyRecord.newValue}</td>
      </tr>
    );
  }

  renderPackagingTableRow = (docRef, doc) => {

    return (
      <tr>
        <td>{doc.description}</td>
        <td>{roundToDollars(doc.cost)}</td>
        <td>{doc.per}</td>
        <td>{doc.quantity}</td>
        <td>{roundToDollars(doc.total_cost)}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            <DropdownButton variant="dark" id="dropdown-basic-button" title="Actions">
              <Dropdown.Item variant="dark" onClick={() => {
                this.toggleDeleteModal(docRef, doc.description)
              }}>Delete</Dropdown.Item>
              <Dropdown.Item variant="dark" onClick={() => {
                this.setState({
                  chosenPackaging: docRef,
                  chosenQuantity: doc.quantity,
                }, () => {
                  this.toggleShowForm();
                });
              }}>Edit</Dropdown.Item>
            </DropdownButton>
          </Stack>
        </td>
      </tr>
    );

  }

  getRecipeSelect = (docRef, doc) => {
    return (
      <>
        <option value={docRef}>{doc.name + '- Makes:' + doc.makes_qty}</option>
      </>
    );
  }

  getPackagingSelect = (docRef, doc) => {
    return (
      <>
        <option value={docRef}>{doc.description + '- Cost:' + roundToDollars(doc.cost)}</option>
      </>
    );
  }

  render() {
    let productPackagingMap = this.makePackagingMap();
    let packagingMap = this.state.packagingMap;
    let recipeMap = this.props.recipeMap;
    let historyMap = this.state.historyMap;
    let lastUpdatedString = "";
    if (this.state.product.last_update) {
      lastUpdatedString = this.state.product.last_update.toDate().toString();
    }

    return (
      <>
        {
          /* 
          * Product 
          */
        }
        <Container fluid>
          {/*
          ------------- start Modal add packaging ---------------
           */}
          <Modal show={this.state.showForm} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.saveChangedProduct}>
                <Form.Group as={Col}>
                  <Form.Label as={Col} htmlFor="packaging">Packaging:</Form.Label>
                  <Form.Select
                    required
                    value={this.state.chosenPackaging}
                    id="packaging"
                    aria-label="Default select example"
                    onChange={this.changeChosenPackingValue}
                  >
                    <option></option>
                    {
                      [...packagingMap].map(([key, value]) => {
                        return this.getPackagingSelect(key, value);
                      }
                      )
                    }
                  </Form.Select>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label as={Col} htmlFor="quantity">Qty:</Form.Label>
                    <Form.Control
                      required
                      value={this.state.chosenQuantity}
                      placeholder="0"
                      id="quantity"
                      onChange={this.changeChosenPackingValue}
                    />
                  </Form.Group>
                </Form.Group>
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={this.toggleShowForm}>
                Cancel
              </Button>{' '}
              <Button variant="secondary" size="sm" onClick={this.addPackaging}>
                Save Packaging
              </Button>
            </Modal.Footer>
          </Modal>
          {/*
          ------------- end Modal add packaging ---------------
           */}
          {/*
          ------------- start Modal delete packaging ---------------
           */}
          <Modal show={this.state.isDeletePackagingConfirmOpen}>
            <div>
              Delete {this.state.deleteDocDescription}?
            </div>
            <Container>
              <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
              <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
            </Container>
          </Modal>
          {/*
          ------------- end Modal delete packaging ---------------
           */}
          <h1>Product {this.props.mode} "{this.state.product.name}"</h1>
          <Form noValidate validated={this.state.validated} onSubmit={this.saveChangedProduct}>
            <Button
              size="sm"
              variant="dark"
              onClick={() => { this.props.toggleForm() }}>
              Cancel
            </Button>{' '}
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              type="submit">
              Save
            </Button>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name">Name:</Form.Label>
                  <Form.Control
                    disabled={this.props.mode === 'View'}
                    required
                    value={this.state.product.name}
                    placeholder="Name"
                    id="name"
                    onChange={this.changeValue}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="recipe">Recipe:</Form.Label>
                  <Form.Select
                    disabled={this.props.mode === 'View'}
                    required
                    value={this.state.product.recipe}
                    id="recipe"
                    aria-label="Default select example"
                    onChange={this.changeValue}
                  >
                    <option></option>
                    {
                      [...recipeMap].map(([key, value]) => {
                        return this.getRecipeSelect(key, value);
                      }
                      )
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Row>
                  <Form.Label as={Col} htmlFor="packaging_cost">Packaging Cost:</Form.Label>
                  <InputGroup as={Col} className="mb-3">
                    <Form.Control
                      value={roundToDollars(this.state.product.packaging_cost)}
                      aria-label="packaging_cost"
                      placeholder="0.00"
                      id="packaging_cost"
                      disabled
                    />
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="cost_high">Cost High Profit:</Form.Label>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Control
                      required
                      value={roundToDollars(this.state.product.cost_high)}
                      placeholder="0"
                      id="cost_high"
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="cost_low">Cost Low Profit:</Form.Label>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Control
                      required
                      value={roundToDollars(this.state.product.cost_low)}
                      placeholder="0"
                      id="cost_low"
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="recipe_qty">Quantity per Product Package:</Form.Label>
              <Form.Label as={Col} htmlFor="price">Our Price:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.product.recipe_qty}
                  placeholder="0"
                  id="recipe_qty"
                  onChange={this.changeValue}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  value={this.state.product.price}
                  placeholder="0"
                  id="price"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Check
                  reverse
                  disabled={this.props.mode === 'View'}
                  type="checkbox"
                  checked={this.state.product.on_website}
                  onChange={this.changeValue}
                  id="on_website"
                  label="On Website?"
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label as={Col} htmlFor="category">Category:</Form.Label>
                <Form.Select
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.product.category}
                  id="category"
                  onChange={this.changeValue}
                >
                  <option key='1' ></option>
                  <option key='2' value='Bread'>Bread</option>
                  <option key='6' value='Cake'>Cake</option>
                  <option key='3' value='Cookies'>Cookies</option>
                  <option key='4' value='Gluten Free'>Gluten Free</option>
                  <option key='7' value='Quick Breads'>Quick Breads</option>
                  <option key='5' value='Rolls'>Rolls</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label as={Col} htmlFor="description">Description:</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={this.props.mode === 'View'}
                  value={this.state.product.description}
                  placeholder="description"
                  id="description"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              Packaging:
            </Row>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenPackaging: "",
                  chosenQuantity: "",
                }, () => {
                  this.toggleShowForm()
                });
              }}>
              Add Packaging
            </Button>
            <Row className="mb-3">
              <Table bordered>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Cost</th>
                    <th>Per</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...productPackagingMap].map(([key, value]) => {
                      return this.renderPackagingTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="ingredient_label_file">Ingredient & Disclaimer Label Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.product.ingredient_label_file}
                  placeholder="Ingredient & Disclaimer Label file ID"
                  id="ingredient_label_file"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="name_label_file">Name Label Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.product.name_label_file}
                  placeholder="Name Label Google file ID"
                  id="name_label_file"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="google_image_file_id">Web site Image Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.product.google_image_file_id}
                  placeholder="Image Google File ID"
                  id="google_image_file_id"
                />
              </Form.Group>
            </Row>
            <Row>
              <Container>
                <Button
                  size="sm"
                  variant="dark"
                  onClick={() => { this.props.toggleForm() }}>
                  Cancel
                </Button>{' '}
                <Button
                  size="sm"
                  disabled={this.props.mode === 'View'}
                  variant="dark"
                  type="submit">
                  Save
                </Button>
              </Container>
            </Row>
            <Row className="mb-3">
              <Form.Label htmlFor="history">History:</Form.Label>
              <Table
                disabled
                id="history"
                bordered>
                <thead>
                  <tr>
                    <th>Update Date</th>
                    <th>Field</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...historyMap].map(([key, value]) => {
                      return this.renderHistoryTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
          </Form>
        </Container >
      </>
    );

  }
}

import { getDoc, setDoc, getDocs, query, doc, collection } from "firebase/firestore";
import { database } from '../firebase-config';
import { roundToDollars } from '../utils/MoneyUtils';
import { History } from '../data/History';
export const COLLECTION = "products";

export class Product {
    document_reference = ""
    name = ""
    description = ""
    category = ""
    cost_high = 0.0
    cost_low = 0.0
    packaging = new Array()
    packaging_cost = 0.0
    ingredient_label_file = ""
    name_label_file = ""
    google_image_file_id = ""
    on_website = false
    price = ""
    recipe = ""
    recipe_qty = 0
    last_update = ""
    history = new Array()
}

export const getProductMap = async() => {
  const productMap =new Map();

  try {
    const docQuery = query(collection(database, COLLECTION));
    const querySnapshot = await getDocs(docQuery);
    querySnapshot.forEach((doc) => {
      productMap.set(doc.id, doc.data());
    });

  } catch (e) {
    console.log("Error getting products:", e);
  }
  return productMap;
}

export const getProduct = async (documentRef) => {
    try {
        const existingDocRef = doc(database, COLLECTION, documentRef);
        const existingDoc = await getDoc(existingDocRef);
        return existingDoc;
    } catch (e) {
        console.log("getProduct(): Error getting Product ref:" + this.props.docRef);
    }
}

export function documentToProduct(existingDoc) {
    let existingProduct = new Product();
    existingProduct.document_reference = existingDoc.id;
    existingProduct.name = existingDoc.data().name;
    existingProduct.cost = existingDoc.data().cost;
    existingProduct.description = existingDoc.data().description;
    existingProduct.category = existingDoc.data().category;
    existingProduct.packaging = existingDoc.data().packaging;
    existingProduct.packaging_cost = existingDoc.data().packaging_cost;
    existingProduct.price = existingDoc.data().price;
    existingProduct.recipe = existingDoc.data().recipe;
    existingProduct.recipe_qty = existingDoc.data().recipe_qty;
    existingProduct.ingredient_label_file = existingDoc.data().ingredient_label_file;
    existingProduct.name_label_file = existingDoc.data().name_label_file;
    existingProduct.google_image_file_id = existingDoc.data().google_image_file_id;
    existingProduct.on_website = (existingDoc.data().on_website ? existingDoc.data().on_website : false);
    existingProduct.last_update = existingDoc.data().last_update;
    existingProduct.history = existingDoc.data().history;

    return existingProduct;
}

export const productNameExists = async (documentRef) => {
  let existingDoc = await getProduct(documentRef);
  if (existingDoc && existingDoc.data() && existingDoc.id === documentRef) {
    return true;
  } else {
    return false;
  }
}
export const saveProduct = async (docRef, productData) => {
    try {
        await setDoc(doc(database, COLLECTION, docRef), productData, { merge: true });
    } catch (e) {
        console.log("Error saving Product:", e);
    }
}

export function recalculateCosts (product, recipeMap, packagingMap) {
    let tempProduct = product;

    let totalPackagingCosts = getTotalPackagingCosts(product, packagingMap);
    let recipeCostHigh = getRecipeCostHigh(product, recipeMap);
    let recipeCostLow = getRecipeCostLow(product, recipeMap);
    let totalCostHigh = totalPackagingCosts + (recipeCostHigh * product.recipe_qty);
    let totalCostLow = totalPackagingCosts + (recipeCostLow * product.recipe_qty);

    tempProduct.cost_high = totalCostHigh;
    tempProduct.cost_low = totalCostLow;
    tempProduct.packaging_cost = totalPackagingCosts;

    return tempProduct;

}

export function getRecipeCostHigh(product, recipeMap) {
    if (!product.recipe || product.recipe === '') {
        console.log('No recipe selected to calculate recipe cost!');
        return 0.0;
    }
    let recipeDoc = recipeMap.get(product.recipe);
    if (recipeDoc) {
        return recipeDoc.cost_per_item_high;
    } else {
        console.log('recipe ref:' + product.recipe + ' not found in recipeMap!');
        return 0.0;
    }
}

export function getRecipeCostLow(product, recipeMap) {
    if (!product.recipe || product.recipe === '') {
        console.log('No recipe selected to calculate recipe cost!');
        return 0.0;
    }
    let recipeDoc = recipeMap.get(product.recipe);
    if (recipeDoc) {
        return recipeDoc.cost_per_item_low;
    } else {
        console.log('recipe ref:' + product.recipe + ' not found in recipeMap!');
        return 0.0;
    }
}

export function getTotalPackagingCosts(product, packagingMap) {
    let packagingTotal = 0.0;
    if (!product.packaging) {
        return packagingTotal;
    }
    product.packaging.forEach((packagingRefQuantity) => {
        let packagingParts = packagingRefQuantity.split(":");
        let packagingRef = packagingParts[0];
        let quantity = packagingParts[1];
        let packagingDoc = packagingMap.get(packagingRef);
        packagingTotal = packagingTotal + (packagingDoc.cost * quantity);
    })
    return packagingTotal;
}

/**
* ------------ Start History ----------------
*/
export const getHistoryRecord = async (history_key) => {
    const existingHistoryRef = doc(database, "history", history_key);
    const existingHistory = await getDoc(existingHistoryRef);
    const historyRecord = new History();
    historyRecord.key = history_key;
    historyRecord.updateDate = existingHistory.data().last_updated;
    historyRecord.field = existingHistory.data().field;
    historyRecord.oldValue = existingHistory.data().oldValue;
    historyRecord.newValue = existingHistory.data().newValue;
    return historyRecord;
  }
  
  export const loadHistoryMap = async (historyList) => {
    const map = new Map();
    for (const history_key of historyList) {
      try {
        let historyRecord = await getHistoryRecord(history_key);
        map.set(history_key, historyRecord);
      } catch (e) {
        console.log("Error getting history ref:" + history_key + " exception:" + e);
      }
    }
    return map;
  }
  
  function addHistory(newHistoryList, now, productName, field, oldValue, newValue) {
    let docData = {
      last_updated: now,
      field: field,
      oldValue: oldValue,
      newValue: newValue,
    };
  
    let documentName = 'product_' + productName + '_' + field + "_" + now;
  
    try {
      setDoc(doc(database, "history", documentName), docData);
    } catch (e) {
      console.log("Error saving History:", e);
      return;
    }
  
    newHistoryList.push(documentName);
  }
  
  function packagingEqual(newPackaging, oldPackaging) {
    if (newPackaging.length !== oldPackaging.length) return false;
    for (let ii = 0; ii < newPackaging.length; ii++) {
      if (newPackaging[ii] !== oldPackaging[ii]) return false;
    }
    return true;
  }
  
  export const recordHistory = async (newHistoryList, now, newProduct, docRef) => {
    let existingDoc = "";
    try {
      let oldDocRef = doc(database, COLLECTION, docRef);
      existingDoc = await getDoc(oldDocRef);
    } catch (e) {
      console.log("Error getting existing Product for History:", e);
    }
  
    try {
      if (newProduct.category !== existingDoc.data().category) {
        addHistory(newHistoryList, now, docRef, "category", existingDoc.data().category, newProduct.category);
      }
      if (newProduct.description !== existingDoc.data().description) {
        addHistory(newHistoryList, now, docRef, "description", existingDoc.data().description,newProduct.description);
      }
      if (newProduct.google_image_file_id !== existingDoc.data().google_image_file_id) {
        addHistory(newHistoryList, now, docRef, "google_image_file_id", existingDoc.data().google_image_file_id, newProduct.google_image_file_id);
      }
      if (newProduct.ingredient_label_file !== existingDoc.data().ingredient_label_file) {
        addHistory(newHistoryList, now, docRef, "ingredient_label_file", existingDoc.data().ingredient_label_file, newProduct.ingredient_label_file);
      }
      if (existingDoc.data().name && newProduct.name !== existingDoc.data().name) {
        addHistory(newHistoryList, now, docRef, "name", existingDoc.data().name, newProduct.name);
      }
      if (newProduct.name_label_file !== existingDoc.data().name_label_file) {
        addHistory(newHistoryList, now, docRef, "name_label_file", existingDoc.data().name_label_file, newProduct.name_label_file);
      }
      if (newProduct.on_website !== existingDoc.data().on_website) {
        addHistory(newHistoryList, now, docRef, "on_website", existingDoc.data().on_website, newProduct.on_website);
      }
      if (newProduct.on_website !== existingDoc.data().on_website) {
        addHistory(newHistoryList, now, docRef, "on_website", existingDoc.data().on_website, newProduct.on_website);
      }
      if (newProduct.packaging_cost !== existingDoc.data().packaging_cost) {
        addHistory(newHistoryList, now, docRef, "packaging_cost", roundToDollars(existingDoc.data().packaging_cost), roundToDollars(newProduct.packaging_cost));
      }
      if (newProduct.price !== existingDoc.data().price) {
        addHistory(newHistoryList, now, docRef, "price", roundToDollars(existingDoc.data().price), roundToDollars(newProduct.price));
      }
      if (newProduct.recipe !== existingDoc.data().recipe) {
        addHistory(newHistoryList, now, docRef, "recipe", existingDoc.data().recipe, newProduct.recipe);
      }
      if (newProduct.recipe_qty !== existingDoc.data().recipe_qty) {
        addHistory(newHistoryList, now, docRef, "recipe_qty", existingDoc.data().recipe_qty, newProduct.recipe_qty);
      }
      if (!packagingEqual(newProduct.packaging,existingDoc.data().packaging)) {
        addHistory(newHistoryList, now, docRef, "packaging", existingDoc.data().recipe_qty, newProduct.recipe_qty);
      }
    } catch (e) {
      console.log("Error adding history for:" + existingDoc.id);
    }
  }
  /**
   * ------------ End History ----------------
   */

import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { getDocs, collection, query, orderBy, deleteDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import { Container, Stack, Button, Table, Form, Row, Col } from "react-bootstrap";
import Modal from "react-modal";
import { IngredientForm } from './IngredientForm';
import { CustomizedTypeahead } from './CustomizedTypeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';



export class IngredientList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allDocs: [],
      docRef: "",
      previousRef: "",
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      isDeleteConfirmOpen: false,
      description: "",
      deleteDocRef: "",
      mode: "Add",
      showForm: false,
      didSave: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.COLLECTION = "ingredients";

  }

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      docMap: new Map(),
      didSave: true,
    });
    this.readDocs();

  }

  toggleMode = (mode) => {
    this.setState({
      docRef: "",
      mode: mode,
      showForm: true,
      didSave: false,
    });
  }


  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      description: description,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  }

  handleUpdate = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: 'Edit',
      didSave: false,
    });
  }

  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      description: description,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  };

  handleDelete = async (docRef) => {
    //await deleteDoc(doc(database, this.COLLECTION, docRef));
    this.state.docMap.delete(docRef);
    this.setState({
      deleteDocRef: "",
      isDeleteConfirmOpen: false,
    });
  }

  async readDocs() {
    let allDocs = new Array();
    let tempOptions = new Array();
    this.setState({
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
    });

    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("description", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.docMap.set(doc.id, doc.data());
        this.state.refMap.set(doc.id, React.createRef());
        let option = { id: doc.id, value: doc.data().description };
        tempOptions.push(option);
        allDocs.push(doc);
      });
      this.setState({
        allDocs: allDocs,
        options: tempOptions,
      });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  componentDidMount() {
    this.readDocs();
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (!this.state.didSave) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  scrollToView(ref) {
    if (this.state.previousRef !== '') {
      let oldRef = this.state.previousRef;
      oldRef.current.style.background = 'white';
      this.setState({
        previousRef: "",
      })
    }
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      ref.current.style.background = 'gray';
      this.setState({
        previousRef: ref,
      })
    });
  }

  renderTableRow = (docRef, doc) => {
    let lastUpdatedString = "";
    if (doc.last_updated) {
      lastUpdatedString = moment(doc.last_updated.toDate()).format('MM/DD/YYYY HH:mm:ss');
    }

    return (
      <tr key={docRef} ref={this.state.refMap.get(docRef)}>
        <td>{doc.description}</td>
        <td>{doc.cost}</td>
        <td>{doc.per}</td>
        <td>{doc.total_price}</td>
        <td>{doc.total_quantity}</td>
        <td>{lastUpdatedString}</td>
        <td>{doc.information}</td>
        <td>
          <DropdownButton variant="dark" id="dropdown-basic-button" title="Actions">
            <Dropdown.Item variant="dark" onClick={() => { this.handleUpdate(docRef) }}>Edit</Dropdown.Item>
          </DropdownButton>
        </td>
      </tr>
    );

  }


  render() {

    let docMap = this.state.docMap;

    return (
      <>
        {
          /* 
          * Ingredient List
          */
        }
        <Stack gap={3}>
          {!this.state.showForm &&
            <Container fluid>
              <Row className="justify-content-md-left">
                <Col>
                  <h2>Ingredients:</h2>
                </Col>
                <Col>
                  <Button variant="dark" onClick={() => { this.toggleMode('Add') }}>Add New Ingredient</Button>
                </Col>
                <Col md="auto">
                  Find:
                </Col>
                <Col md="auto">
                  <CustomizedTypeahead
                    onChange={(selected) => {
                      console.log("selected" + selected);
                      if (selected.length > 0) {
                        let ref = this.state.refMap.get(selected[0].id);
                        this.scrollToView(ref);
                      }
                    }}
                    align='left'
                    labelKey={'value'}
                    options={this.state.options}
                  />
                </Col>
              </Row>
            </Container>
          }
          {this.state.showForm &&
            <IngredientForm
              docRef={this.state.docRef}
              mode={this.state.mode}
              toggleForm={() => { this.toggleShowForm() }}
            />
          }
          <Modal
            className='modalDialog'
            isOpen={this.state.isDeleteConfirmOpen}
            onRequestClose={this.toggleModal}
            contentLabel="My dialog"
          >
            <div>
              Delete {this.state.description}?
            </div>
            <Button variant="dark" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
            <Button variant="dark" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
          </Modal>
          {!this.state.showForm &&
            <div className='scrollingTable'>
              <Table bordered hover>
                <thead>
                  <tr className='header'>
                    <th className='table-header'>Description</th>
                    <th className='table-header'>Cost</th>
                    <th className='table-header'>Per</th>
                    <th className='table-header'>Total Price</th>
                    <th className='table-header'>Total Quantity</th>
                    <th className='table-header'>Last Updated</th>
                    <th className='table-header'>Information</th>
                    <th className='table-header'></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...docMap].map(([key, value]) => {
                      return this.renderTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </div>
          }
        </Stack>
      </>
    );

  }
}
import React from 'react';
import './../index.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import { Labels } from './Labels';
import { DashboardHome } from './DashboardHome';
import { Title } from './Title';
import { UserAuthContextProvider } from './userAuthContext';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Stack } from 'react-bootstrap';
import { PackagingList } from './PackagingList';
import { IngredientList } from './IngredientList';
import { RecipeList } from './RecipeList';
import { ProductList } from './ProductList';
import { EventList } from './EventList';

export class Navigation extends React.Component {

  render() {
    return (
      <>
        {
          /*
          * Navigation section
          */
        }          
        <Stack gap={3}>
        <div className='header'>
          <Navbar className='nav' >
            <Container>
              <Nav className="me-auto">
                <Nav.Link href="/home">Home</Nav.Link>
                <Nav.Link href="/eventList">Events</Nav.Link>
                <Nav.Link href="/productList">Products</Nav.Link>
                <Nav.Link href="/recipeList">Recipes</Nav.Link>
                <Nav.Link href="/ingredientList">Ingredients</Nav.Link>
                <Nav.Link href="/packagingList">Packaging</Nav.Link>
                <Nav.Link href="/labels">Labels</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </div>
        <UserAuthContextProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={
              <ProtectedRoute>
                <DashboardHome />
              </ProtectedRoute>
            } />
            <Route path="/eventList" element={
              <ProtectedRoute>
                <EventList />
              </ProtectedRoute>
            } />
            <Route path="/productList" element={
              <ProtectedRoute>
                <ProductList />
              </ProtectedRoute>
            } />
            <Route path="/recipeList" element={
              <ProtectedRoute>
                <RecipeList />
              </ProtectedRoute>
            } />
            <Route path="/ingredientList" element={
              <ProtectedRoute>
                <IngredientList />
              </ProtectedRoute>
            } />
            <Route path="/packagingList" element={
              <ProtectedRoute>
                <PackagingList />
              </ProtectedRoute>
            } />
            <Route path="/labels" element={
              <ProtectedRoute>
                <Labels />
              </ProtectedRoute>
            } />
          </Routes>
        </UserAuthContextProvider>
        </Stack>
      </>
    );
  }


}

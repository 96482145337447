import { getDoc, setDoc, query, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import { roundToDollars } from '../utils/MoneyUtils';
import { History } from './History';
export const COLLECTION = "events";

export class Event {
    document_reference = ""
    name = ""
    description = ""
    notes = ""
    products = new Array()
    location = ""
    profit = 0
    date = new Date()
    time = ""
    last_update = ""
    history = new Array()
}

export const getEvent = async (documentRef) => {
    try {
        const existingDocRef = doc(database, COLLECTION, documentRef);
        const existingDoc = await getDoc(existingDocRef);
        return existingDoc;
    } catch (e) {
        console.log("getEvent(): Error getting Event ref:" + this.props.docRef);
    }
}

export function documentToEvent(existingDoc) {
    let existingEvent = new Event();
    existingEvent.document_reference = existingDoc.id;
    existingEvent.name = existingDoc.data().name;
    existingEvent.description = existingDoc.data().description;
    existingEvent.notes = existingDoc.data().notes;
    existingEvent.products = existingDoc.data().products;
    existingEvent.location = existingDoc.data().location;
    existingEvent.profit = existingDoc.data().profit;
    existingEvent.date = existingDoc.data().date;
    existingEvent.time = existingDoc.data().time;
    existingEvent.last_update = existingDoc.data().last_update;
    existingEvent.history = existingDoc.data().history;

    return existingEvent;
}

export const saveEvent = async (docRef, eventData) => {
    try {
        await setDoc(doc(database, COLLECTION, docRef), eventData, { merge: true });
    } catch (e) {
        console.log("Error saving Event:", e);
    }
}

/**
* ------------ Start History ----------------
*/
export const getHistoryRecord = async (history_key) => {
    const existingHistoryRef = doc(database, "history", history_key);
    const existingHistory = await getDoc(existingHistoryRef);
    const historyRecord = new History();
    historyRecord.key = history_key;
    historyRecord.updateDate = existingHistory.data().last_updated;
    historyRecord.field = existingHistory.data().field;
    historyRecord.oldValue = existingHistory.data().oldValue;
    historyRecord.newValue = existingHistory.data().newValue;
    return historyRecord;
  }
  
  export const loadHistoryMap = async (historyList) => {
    const map = new Map();
    for (const history_key of historyList) {
      try {
        let historyRecord = await getHistoryRecord(history_key);
        map.set(history_key, historyRecord);
      } catch (e) {
        console.log("Error getting history ref:" + history_key + " exception:" + e);
      }
    }
    return map;
  }
  
  function addHistory(newHistoryList, now, eventName, field, oldValue, newValue) {
    let docData = {
      last_updated: now,
      field: field,
      oldValue: oldValue,
      newValue: newValue,
    };
  
    let documentName = 'event_' + eventName + '_' + field + "_" + now;
  
    try {
      setDoc(doc(database, "history", documentName), docData);
    } catch (e) {
      console.log("Error saving History:", e);
      return;
    }
  
    newHistoryList.push(documentName);
  }
  
  function productsEqual(newPackaging, oldPackaging) {
    if (newPackaging.length !== oldPackaging.length) return false;
    for (let ii = 0; ii < newPackaging.length; ii++) {
      if (newPackaging[ii] !== oldPackaging[ii]) return false;
    }
    return true;
  }
  
  export const recordHistory = async (newHistoryList, now, newEvent, docRef) => {
    let existingDoc = "";
    try {
      let oldDocRef = doc(database, COLLECTION, docRef);
      existingDoc = await getDoc(oldDocRef);
    } catch (e) {
      console.log("Error getting existing Event for History:", e);
    }
  
    try {
      if (newEvent.name !== existingDoc.data().name) {
        addHistory(newHistoryList, now, docRef, "name", existingDoc.data().name, newEvent.name);
      }
      if (newEvent.description !== existingDoc.data().description) {
        addHistory(newHistoryList, now, docRef, "description", existingDoc.data().description,newEvent.description);
      }
      if (newEvent.notes !== existingDoc.data().notes) {
        addHistory(newHistoryList, now, docRef, "notes", existingDoc.data().notes,newEvent.notes);
      }
      if (!productsEqual(newEvent.products, existingDoc.data().products)) {
        addHistory(newHistoryList, now, docRef, "products", existingDoc.data().products, newEvent.products);
      }
      if (newEvent.location !== existingDoc.data().location) {
        addHistory(newHistoryList, now, docRef, "location", existingDoc.data().location,newEvent.location);
      }
      if (newEvent.profit !== existingDoc.data().profit) {
        addHistory(newHistoryList, now, docRef, "profit", existingDoc.data().profit,newEvent.locprofitation);
      }
      if (newEvent.date !== existingDoc.data().date) {
        addHistory(newHistoryList, now, docRef, "date", existingDoc.data().date,newEvent.date);
      }
      if (newEvent.time !== existingDoc.data().time) {
        addHistory(newHistoryList, now, docRef, "time", existingDoc.data().time,newEvent.time);
      }
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }
  }
  /**
   * ------------ End History ----------------
   */

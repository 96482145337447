import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { getDoc, setDoc, doc, Timestamp } from "firebase/firestore";
import { database } from '../firebase-config';
import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table
} from "react-bootstrap";
import {
  Ingredient, recordHistory, getIngredient, documentToIngredient, loadHistoryMap,
  saveIngredient
} from '../data/IngredientData';

export class IngredientForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ingredient: new Ingredient(),
      history: new Array(),
      historyMap: new Map(),
      dataLoading: true,
      docs: [],
      validated: false,
      isOpen: false,
      modalString: "",

    };

    this.changeValue = this.changeValue.bind(this);
    this.COLLECTION = "ingredients";
  }

  handleClose = () => this.setState({ isOpen: false });

  async getDocumentFromDB() {
    try {
      let existingIngredientDoc = await getIngredient(this.props.docRef);

      let newHistoryMap = new Map();
      if (existingIngredientDoc.data().history && existingIngredientDoc.data().history.length !== 0) {
        newHistoryMap = await loadHistoryMap(existingIngredientDoc.data().history);
      }
      let existingIngredient = documentToIngredient(existingIngredientDoc);

      this.setState({
        ingredient: existingIngredient,
        historyMap: newHistoryMap
      });
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }

    this.setState({
      dataLoading: false,
    })

  }

  componentDidMount() {
    if (this.props.docRef !== '') {
      this.getDocumentFromDB();
    } else {
      this.setState({
        dataLoading: false,
      })
    }
  }

  handleStoreLookup = async (doc, store) => {
    let searchString = "";
    if (this.state.ingredient.description) {
      searchString = encodeURIComponent(this.state.ingredient.description);
    }
    let url = ""
    switch (store) {
      case 'king_soopers':
        //https://www.kingsoopers.com/search?query=vanilla%20extract&searchType=default_search&fulfillment=ais
        url = 'https://www.kingsoopers.com/search?query=' + searchString + '&searchType=default_search&fulfillment=ais';
        break;
      case "walmart":
        //https://www.walmart.com/search?q=almond+milk&typeahead=almont&facet=fulfillment_method_in_store%3AIn-store
        url = 'https://www.walmart.com/search?q=' + searchString + '&typeahead=almont&facet=fulfillment_method_in_store%3AIn-store';
        break;
      case "sams":
        //https://www.samsclub.com/s/almond%20milk?selectedFilter=club
        url = 'https://www.samsclub.com/s/' + searchString + '?selectedFilter=club';
        break;
      case "natural_grocers":
        //https://www.naturalgrocers.com/product-finder?field_location=0&field_distance_to_search=60&sort_by=nid&sort_order=DESC&search_api_fulltext=almond+milk
        url = 'https://www.naturalgrocers.com/product-finder?field_location=0&field_distance_to_search=60&sort_by=nid&sort_order=DESC&search_api_fulltext=' + searchString;
        break;
      case "costco":
        //https://www.costco.com/s?keyword=almond+milk&refine=item_program_eligibility-InWarehouse%257C%257Citem_location_availability-in%2520stock
        url = 'https://www.costco.com/s?keyword=' + searchString + '&refine=item_program_eligibility-InWarehouse%257C%257Citem_location_availability-in%2520stock';
        break;
      default:
        break;
    }

    window.open(url, '_blank');
  }

  changeValue(event) {
    if (event.target.id === 'description') {
      let tempIngredient = this.state.ingredient;
      tempIngredient.description = event.target.value;
      this.setState({ ingredient: tempIngredient });
    } else if (event.target.id === 'cost') {
      let tempIngredient = this.state.ingredient;
      tempIngredient.cost = event.target.value;
      this.setState({ ingredient: tempIngredient });
    } else if (event.target.id === 'perselect') {
      let tempIngredient = this.state.ingredient;
      tempIngredient.per = event.target.value;
      this.setState({ ingredient: tempIngredient });
    } else if (event.target.id === 'totalprice') {
      if (event.target.value !== ''
        && this.state.ingredient.total_quantity !== ''
        && typeof parseFloat(event.target.value) == 'number'
        && typeof parseInt(this.state.ingredient.total_quantity) == 'number'
        && parseInt(this.state.ingredient.total_quantity) !== 0) {
        let tempCost = Math.round(parseFloat(event.target.value) / parseInt(this.state.ingredient.total_quantity) * 100) / 100;
        let tempIngredient = this.state.ingredient;
        tempIngredient.cost = tempCost;
        this.setState({ ingredient: tempIngredient });
      }
      let tempIngredient = this.state.ingredient;
      tempIngredient.total_price = event.target.value;
      this.setState({ ingredient: tempIngredient });
    } else if (event.target.id === 'totalqty') {
      if (event.target.value !== ''
        && this.state.ingredient.total_price !== ''
        && typeof parseFloat(event.target.value) == 'number'
        && typeof parseInt(this.state.ingredient.total_price) == 'number'
        && parseInt(this.state.ingredient.total_quantity) !== 0) {
        let tempCost = Math.round(parseFloat(this.state.ingredient.total_price) / parseFloat(event.target.value) * 100) / 100;
        let tempIngredient = this.state.ingredient;
        tempIngredient.cost = tempCost;
        this.setState({ ingredient: tempIngredient });
      }
      let tempIngredient = this.state.ingredient;
      tempIngredient.total_quantity = event.target.value;
      this.setState({ ingredient: tempIngredient });
    } else if (event.target.id === 'information') {
      let tempIngredient = this.state.ingredient;
      tempIngredient.information = event.target.value;
      this.setState({ ingredient: tempIngredient });
    }
  }

  saveChangedIngredient = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    let now = Timestamp.now();

    let documentName = '';
    if (this.props.docRef === '') {
      const regex2 = /[^A-Za-z0-9_]/ig;
      documentName = this.state.ingredient.description.replaceAll(regex2, '_');
    } else {
      documentName = this.props.docRef;
    }

    let newHistoryList = "";
    if (this.state.ingredient.history && this.state.ingredient.history.length !== 0) {
      newHistoryList = this.state.ingredient.history;
    } else {
      newHistoryList = new Array();
    }

    if (this.props.mode !== 'Add') {
      await recordHistory(newHistoryList, now, this.state.ingredient, documentName);
    }

    let docData = {
      description: this.state.ingredient.description,
      cost: this.state.ingredient.cost,
      per: this.state.ingredient.per,
      total_price: this.state.ingredient.total_price,
      total_quantity: this.state.ingredient.total_quantity,
      information: this.state.ingredient.information,
      last_updated: now,
      history: newHistoryList
    };

    saveIngredient(documentName, docData);

    this.setState({
      ingredient: new Ingredient(),
      validated: false,
      isOpen: true,
      modalString: "Ingredients added!",
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  renderHistoryTableRow = (historyDocRef, historyRecord) => {
    let updatedDate = historyRecord.updateDate.toDate().toString();
    return (
      <tr>
        <td>{updatedDate}</td>
        <td>{historyRecord.field}</td>
        <td>{historyRecord.oldValue}</td>
        <td>{historyRecord.newValue}</td>
      </tr>
    );
  }

  render() {
    let historyMap = this.state.historyMap;
    let lastUpdatedString = "";
    if (this.state.ingredient.last_updated) {
      lastUpdatedString = this.state.ingredient.last_updated.toDate().toString();
    }
    return (
      <>
        {
          /* 
          * Ingredient Add
          */
        }
        <Container fluid>
          <Modal show={this.state.isOpen} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Ingredients</Modal.Title>
            </Modal.Header>
            <Modal.Body>Ingredient Added!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <h1>{this.props.mode} Ingredient</h1>
          <h3>Store Lookups:</h3>
          <Button className="buttons"
            variant="dark"
            size="sm"
            onClick={() => { this.handleStoreLookup(doc, "king_soopers") }}
          >King Soopers</Button>
          <Button className="buttons"
            variant="dark"
            size="sm"
            onClick={() => { this.handleStoreLookup(doc, "walmart") }}
          >WalMart</Button>
          <Button className="buttons"
            variant="dark"
            size="sm"
            onClick={() => { this.handleStoreLookup(doc, "sams") }}
          >Sams</Button>
          <Button className="buttons"
            variant="dark"
            size="sm"
            onClick={() => { this.handleStoreLookup(doc, "natural_grocers") }}
          >Natural Grocers</Button>
          <Button className="buttons"
            variant="dark"
            size="sm"
            onClick={() => { this.handleStoreLookup(doc, "costco") }}
          >Costco</Button>
          <Form noValidate validated={this.state.validated} onSubmit={this.saveChangedIngredient}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description:</Form.Label>
              <Form.Control
                required
                value={this.state.ingredient.description}
                placeholder="Description"
                id="description"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Row>
              <Col>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="totalprice">Total Purchase Price:</Form.Label>
                  <InputGroup as={Col} className="mb-3">
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      required
                      value={this.state.ingredient.total_price}
                      aria-label="totalprice"
                      placeholder="0.00"
                      id="totalprice"
                      onChange={this.changeValue}
                    />
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="totalqty">Total Purchased Quantity:</Form.Label>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Control
                      required
                      value={this.state.ingredient.total_quantity}
                      placeholder="0"
                      id="totalqty"
                      onChange={this.changeValue}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="perselect">Per:</Form.Label>
                  <Form.Group as={Col}>
                    <Form.Select
                      required
                      value={this.state.ingredient.per}
                      id="perselect"
                      aria-label="Default select example"
                      onChange={this.changeValue}
                    >
                      <option></option>
                      <option value="cup">1 cup</option>
                      <option value="teaspoon">1 teaspoon</option>
                      <option value="tablespoon">1 tablespoon</option>
                      <option value="ounce">1 ounce</option>
                      <option value="piece">1 piece</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Col>
              <Col>
                <Row className="mb-3">
                  <Form.Label as={Col} htmlFor="cost">Calculated Cost:</Form.Label>
                  <InputGroup as={Col} className="mb-3">
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      value={this.state.ingredient.cost}
                      aria-label="Cost"
                      placeholder="0.00"
                      id="cost"
                      //onChange={this.changeValue}
                      disabled
                    />
                  </InputGroup>

                </Row>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="info">Information:</Form.Label>
              <Form.Control
                value={this.state.ingredient.information}
                as="textarea"
                id="information"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="lastupdated">Last Updated:</Form.Label>
              <Form.Control
                value={lastUpdatedString}
                id="lastupdated"
                disabled
              />
            </Form.Group>
            <Button variant="dark" onClick={() => { this.props.toggleForm() }}>
              Cancel
            </Button>{' '}
            <Button variant="dark" type="submit">
              Save
            </Button>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="history">History:</Form.Label>
              <Table
                disabled
                id="history"
                bordered>
                <thead>
                  <tr>
                    <th>Update Date</th>
                    <th>Field</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...historyMap].map(([key, value]) => {
                      return this.renderHistoryTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Form.Group>
          </Form>
        </Container>
      </>

    );

  }
}

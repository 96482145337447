import * as React from "react";
import "./../index.css";
import "./../fonts/Bakerie/BakerieSmoothCondensed-Black.otf";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Stack
} from "react-bootstrap";
import { getDocs, collection, query, orderBy, deleteDoc, doc, where } from "firebase/firestore";
import { database } from '../firebase-config';

import Modal from "react-modal";
import { ProductForm } from './ProductForm';
import { roundToDollars } from '../utils/MoneyUtils';
import { CustomizedTypeahead } from './CustomizedTypeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { getRecipeMapFromDB } from "../data/RecipeData";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


export class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipeMap: new Map(),
      allDocs: [],
      docRef: "",
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      previousRef: "",
      isDeleteConfirmOpen: false,
      deleteDocName: "",
      deleteDocRef: "",
      mode: "Add",
      showForm: false,
      didSave: true,
      currentlySelectedId: '',
      chosenCategory: "All"
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.COLLECTION = "products";
  }

  toggleShowForm = () => {
    this.setState(
      {
        showForm: !this.state.showForm,
        docMap: new Map(),
        didSave: true,
      },
      () => {
        this.readDocs(this.state.chosenCategory);
      }
    );
  };

  toggleMode = (mode) => {
    this.setState({
      docRef: "",
      mode: mode,
      showForm: true,
      didSave: false,
    });
  };

  toggleDeleteModal = (docRef, name) => {

    this.setState({
      deleteDocRef: docRef,
      deleteDocName: name,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  };

  handleUpdate = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "Edit",
      didSave: false,
    });
  };
  
  handleCopy = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: 'Copy',
      didSave: false,
    });
  }

  handleView = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "View",
      didSave: true,
    });
  };
  async buildFindOptions() {
    let tempOptions = new Array();
    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));
      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        let option = { id: doc.id, value: doc.data().name };
        tempOptions.push(option);
      });
      this.setState({
        options: tempOptions,
      });
    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  handleDelete = async (docRef) => {
    await deleteDoc(doc(database, this.COLLECTION, docRef));
    this.state.docMap.delete(docRef);
    this.buildFindOptions();
    this.setState({
      deleteDocRef: "",
      isDeleteConfirmOpen: false,
    });
  };

  async readDocs(category) {
    let allDocs = new Array();
    let tempOptions = new Array();
    let categoryString='All'
    if(category) {
      categoryString=category;
    }
    this.setState({
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      chosenCategory: categoryString,
    });

    try {

      let docQuery = '';
      if (category && category != "All") {
        docQuery = query(collection(database, this.COLLECTION),
          where('category', '==', category),
          orderBy("name", "asc"));
      } else {
        docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));
      }

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.docMap.set(doc.id, doc.data());
        this.state.refMap.set(doc.id, React.createRef());
        let option = { id: doc.id, value: doc.data().name };
        tempOptions.push(option);
        allDocs.push(doc);
      });
      this.setState({
        allDocs: allDocs,
        options: tempOptions,
      });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  async loadData() {
    this.readDocs();
    let recipeMap = await getRecipeMapFromDB();
    this.setState({
      recipeMap: recipeMap,
    })
  }

  componentDidMount() {
    this.loadData();
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (!this.state.didSave) {
      e.preventDefault();
      e.returnValue = "";
    }
  }

  scrollToView(ref) {
    if (this.state.previousRef !== '' && this.state.previousRef.current) {
      let oldRef = this.state.previousRef;
      oldRef.current.style.background = 'white';
      this.setState({
        previousRef: "",
      })
    }
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      ref.current.style.background = 'gray';
      this.setState({
        previousRef: ref,
      })
    });
  }

  renderTableRow = (docRef, doc) => {
    let recipeCostPerItemHigh = this.state.recipeMap.get(doc.recipe).cost_per_item_high;
    let recipeCostPerItemLow = this.state.recipeMap.get(doc.recipe).cost_per_item_low;
    let productCostHigh = doc.packaging_cost + recipeCostPerItemHigh * doc.recipe_qty;
    let productCostLow = doc.packaging_cost + recipeCostPerItemLow * doc.recipe_qty;
    let recipeMakes = this.state.recipeMap.get(doc.recipe).makes_qty;
    let name = doc.name;
    let ingredientLabelFile = "";
    if (doc.ingredient_label_file) {
      ingredientLabelFile =
        "https://drive.google.com/uc?export=view&id=" +
        doc.ingredient_label_file;
    }
    let nameLabelFile = "";
    if (doc.name_label_file) {
      nameLabelFile =
        "https://drive.google.com/uc?export=view&id=" + doc.name_label_file;
    }
    let lastUpdatedString = "";
    if (doc.last_update) {
      lastUpdatedString = moment(doc.last_update.toDate()).format('MM/DD/YYYY HH:mm:ss');
    }

    return (
      <tr key={docRef} ref={this.state.refMap.get(docRef)} id={docRef}>
        <td>{doc.name}</td>
        <td>{roundToDollars(doc.price)}</td>
        <td>{doc.on_website ? "Y" : "N"}</td>
        <td>{roundToDollars(productCostHigh)}</td>
        <td>{roundToDollars(productCostLow)}</td>
        <td>{this.state.recipeMap.get(doc.recipe).name}</td>
        <td>{recipeMakes}</td>
        <td>{doc.recipe_qty}</td>
        <td>{roundToDollars(recipeCostPerItemHigh)}</td>
        <td>{roundToDollars(recipeCostPerItemLow)}</td>
        <td>{this.state.recipeMap.get(doc.recipe).low_profit_index}</td>
        <td>{roundToDollars(this.state.recipeMap.get(doc.recipe).total_ingredient_cost)}</td>
        <td>{roundToDollars(doc.packaging_cost)}</td>
        <td>{lastUpdatedString}</td>
        <td>
          <DropdownButton variant="dark" id="dropdown-basic-button" title="Actions">
            <Dropdown.Item variant="dark" onClick={() => { this.handleView(docRef) }}>View</Dropdown.Item>
            <Dropdown.Item variant="dark" onClick={() => { this.handleUpdate(docRef) }}>Edit</Dropdown.Item>
            <Dropdown.Item variant="dark" onClick={() => { this.handleCopy(docRef) }}>Copy</Dropdown.Item>
            <Dropdown.Item variant="dark" onClick={() => { this.toggleDeleteModal(docRef, name) }}>Delete</Dropdown.Item>
            {doc.ingredient_label_file && (
              <Dropdown.Item variant="dark" href={ingredientLabelFile} download={ingredientLabelFile}>Ingredient & Disclaimer Labels</Dropdown.Item>
            )}
            {doc.name_label_file && (
              <Dropdown.Item variant="dark" href={nameLabelFile} download={nameLabelFile}>Name Labels</Dropdown.Item>
            )}
          </DropdownButton>
        </td>
      </tr >
    );
  };

  render() {
    let docMap = this.state.docMap;

    return (
      <>
        {
          /* 
          * Product List
          */
        }
        <Stack gap={3}>
          {this.state.showForm &&
            <ProductForm
              recipeMap={this.state.recipeMap}
              docRef={this.state.docRef}
              mode={this.state.mode}
              toggleForm={() => {
                this.toggleShowForm();
              }}
            />
          }
          {!this.state.showForm &&
            <Container fluid>
              <Row className="justify-content-md-left">
                <Col>
                  <h2>Products:</h2>
                </Col>
                <Col>
                  <Button variant="dark" onClick={() => { this.toggleMode('Add') }}>Add New Product</Button>
                </Col>
                <Col>
                  <DropdownButton variant="dark" id="dropdown-basic-button" title="Filter by Category">
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs(undefined) }}>All</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Bread') }}>Bread</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Cake') }}>Cake</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Cookies') }}>Cookies</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Gluten Free') }}>Gluten Free</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Quick Breads') }}>Quick Breads</Dropdown.Item>
                    <Dropdown.Item variant="dark" onClick={() => { this.readDocs('Rolls') }}>Rolls</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col md="auto">
                <b>Category:</b> {this.state.chosenCategory}
                </Col>
                <Col md="auto">
                  Find:
                </Col>
                <Col md="auto">
                  <CustomizedTypeahead
                    onChange={(selected) => {
                      console.log("selected" + selected);
                      if (selected.length > 0) {
                        let ref = this.state.refMap.get(selected[0].id);
                        this.scrollToView(ref);
                      }
                    }}
                    align='left'
                    labelKey={'value'}
                    options={this.state.options}
                  />
                </Col>
              </Row>
            </Container>
          }
          <Modal
            className="modalDialog"
            isOpen={this.state.isDeleteConfirmOpen}
            onRequestClose={this.toggleModal}
            contentLabel="My dialog"
          >
            <div>Delete {this.state.deleteDocName}?</div>
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.toggleDeleteModal();
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.handleDelete(this.state.deleteDocRef);
              }}
            >
              Confirm
            </Button>
          </Modal>
          {!this.state.showForm && (
            <div className="scrollingTable">
              <Table bordered hover>
                <thead>
                  <tr className='header'>
                    <th className='table-header'>Name</th>
                    <th className='table-header'>Our Price</th>
                    <th className='table-header'>Website?</th>
                    <th className='table-header'>Cost High</th>
                    <th className='table-header'>Cost Low</th>
                    <th className='table-header'>Recipe</th>
                    <th className='table-header'>Recipe Makes</th>
                    <th className='table-header'>Quantity per Product Pkg</th>
                    <th className='table-header'>Recipe Cost High</th>
                    <th className='table-header'>Recipe Cost Low</th>
                    <th className='table-header'>Recipe Low Profit Index</th>
                    <th className='table-header'>Recipe Ingredient Cost</th>
                    <th className='table-header'>Packaging Cost</th>
                    <th className='table-header'>Last Update</th>
                    <th className='table-header'></th>
                  </tr>
                </thead>
                <tbody>
                  {[...docMap].map(([key, value]) => {
                    return this.renderTableRow(key, value);
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Stack>
      </>
    );
  }
}

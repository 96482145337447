import { getDocs, collection, doc, query } from "firebase/firestore";
import { database } from '../firebase-config';
export const COLLECTION = "packaging";

export const getPackagingMap = async() => {
    const packagingMap =new Map();

    try {
      const docQuery = query(collection(database, COLLECTION));
      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        packagingMap.set(doc.id, doc.data());
      });

    } catch (e) {
      console.log("Error getting packaging:", e);
    }
    return packagingMap;
  }
export class History {
    key = ""
    updateDate = ""
    field = ""
    oldValue = ""
    newValue = ""
    constructor(key,updateDate,field,oldValue,newValue) {
        this.key = key;
        this.updateDate = updateDate;
        this.field = field;
        this.oldValue = oldValue;
        this.newValue = newValue;
    }

}
import { getDoc, setDoc, doc, query, collection, getDocs } from "firebase/firestore";
import { database } from '../firebase-config';
import { roundToDollars } from '../utils/MoneyUtils';
import { History } from '../data/History';

export const default_low_profit_index = 1.4;
export const default_hourly_rate = 15.00;
const COLLECTION = "recipes";

export class Recipe {
  document_reference = ""
  name = ""
  hours_taken = 0
  makes_qty = 0
  note = ""
  cost_per_item_high = 0
  cost_per_item_low = 0
  total_ingredient_cost = 0
  total_batch_cost_high = 0
  total_batch_cost_low = 0
  low_profit_index = default_low_profit_index
  ingredients = new Array()
  last_update = ""
  history = new Array()
}

export const getRecipeMapFromDB = async () => {
  let recipeMap = new Map();
  try {
    const docQuery = query(collection(database, COLLECTION));

    const querySnapshot = await getDocs(docQuery);
    querySnapshot.forEach((doc) => {
      recipeMap.set(doc.id, doc.data());
    });
  } catch (e) {
    console.log("Error getting ingredients:", e);
  }
  return recipeMap;
};

export function makeIngredientMap(ingredients, ingredientMap) {
  let map = new Map();
  if (!ingredients || ingredients.size === 0) {
    return map;
  }
  ingredients.forEach(ingredientRefQuantity => {
    let ingredientParts = ingredientRefQuantity.split(":");
    let ingredientRef = ingredientParts[0];
    let quantity = ingredientParts[1];
    let ingredientDoc = ingredientMap.get(ingredientRef)
    if (ingredientDoc && ingredientDoc !== '') {
      map.set(ingredientRef, {
        "description": ingredientDoc.description,
        "cost": ingredientDoc.cost,
        "per": ingredientDoc.per,
        "quantity": quantity,
        "total_cost": (ingredientDoc.cost * quantity),
      });
    }
  });
  return map;
}

export const getRecipe = async(documentRef) => {
  try {
    const existingDocRef = doc(database, COLLECTION, documentRef);
    const existingDoc = await getDoc(existingDocRef);
    return existingDoc;
  } catch (e) {
    console.log("getRecipe(): Error getting Recipe ref:" + this.props.docRef);
  }
}

export const recipeNameExists = async (documentRef) => {
  let existingDoc = await getRecipe(documentRef);
  if (existingDoc.data()) {
    return true;
  } else {
    return false;
  }
}

export function documentToRecipe(document) {
  let recipe = new Recipe();
  recipe.document_reference = document.id;   
  recipe.name = document.data().name;
  recipe.cost_per_item = document.data().cost_per_item;
  recipe.hours_taken = document.data().hours_taken;
  recipe.makes_qty = document.data().makes_qty;
  recipe.note = document.data().note;
  recipe.cost_per_item_high = document.data().cost_per_item_high;
  recipe.cost_per_item_low = document.data().cost_per_item_low;
  recipe.total_batch_cost_high = document.data().total_batch_cost_high;
  recipe.total_batch_cost_low = document.data().total_batch_cost_low;
  recipe.total_ingredient_cost = document.data().total_ingredient_cost;
  recipe.total_high_profit = document.data().total_high_profit;
  recipe.total_low_profit = document.data().total_low_profit;
  recipe.low_profit_index = document.data().low_profit_index;
  recipe.ingredients = document.data().ingredients;
  recipe.last_update = document.data().last_update;
  recipe.history = document.data().history;

  return recipe;
}

export function getTotalIngredientCosts(ingredients,ingredientMap) {
  let ingredientsTotal = 0.0;
  ingredients.forEach((ingredientRefQuantity) => {
    let ingredientParts = ingredientRefQuantity.split(":");
    let ingredientRef = ingredientParts[0];
    let quantity = ingredientParts[1];
    let ingredientDoc = ingredientMap.get(ingredientRef);
    ingredientsTotal = ingredientsTotal + (ingredientDoc.cost * quantity);
  })
  return ingredientsTotal;
}

export function recalculateCosts(inRecipe,ingredientMap) {
  const tempRecipe = inRecipe;

  /**
   * If the hours taken or makes quantity or ingredients are null or 0, just return.
   */
  if (tempRecipe.hours_taken === ''
    || tempRecipe.hours_taken === 0) {
    return tempRecipe;
  }

  if (tempRecipe.makes_qty === ''
    || tempRecipe.makes_qty === 0) {
    return tempRecipe;
  }

  if (tempRecipe.ingredients === ''
    || tempRecipe.ingredients.size === 0) {
    return tempRecipe;
  }

  let totoalIngredientsCost = getTotalIngredientCosts(tempRecipe.ingredients,ingredientMap);
  let totalTimeAndMaterialCost = totoalIngredientsCost + (default_hourly_rate * tempRecipe.hours_taken);
  let totatBatchCostHigh = totalTimeAndMaterialCost * 1.7;
  let totatBatchCostLow = totalTimeAndMaterialCost * tempRecipe.low_profit_index;
  let costPerItemHigh = totatBatchCostHigh / tempRecipe.makes_qty;
  let costPerItemLow = totatBatchCostLow / tempRecipe.makes_qty;

  tempRecipe.total_ingredient_cost = totoalIngredientsCost;
  tempRecipe.total_batch_cost_high = totatBatchCostHigh;
  tempRecipe.total_batch_cost_low = totatBatchCostLow;
  tempRecipe.cost_per_item_high = costPerItemHigh;
  tempRecipe.cost_per_item_low = costPerItemLow;

  return tempRecipe;
}

export const saveRecipe = async (docRef,recipeData) => {
  try {
    await setDoc(doc(database, COLLECTION, docRef), recipeData, { merge: true });
  } catch (e) {
    console.log("Error saving Recipe:", e);
  }
}
/**
* ------------ Start History ----------------
*/
export const getHistoryRecord = async (history_key) => {
  const existingHistoryRef = doc(database, "history", history_key);
  const existingHistory = await getDoc(existingHistoryRef);
  const historyRecord = new History();
  historyRecord.key = history_key;
  historyRecord.updateDate = existingHistory.data().last_updated;
  historyRecord.field = existingHistory.data().field;
  historyRecord.oldValue = existingHistory.data().oldValue;
  historyRecord.newValue = existingHistory.data().newValue;
  return historyRecord;
}

export const loadHistoryMap = async (historyList) => {
  const map = new Map();
  for (const history_key of historyList) {
    try {
      let historyRecord = await getHistoryRecord(history_key);
      map.set(history_key, historyRecord);
    } catch (e) {
      console.log("Error getting history ref:" + history_key + " exception:" + e);
    }
  }
  return map;
}

function addHistory(newHistoryList, now, recipeName, field, oldValue, newValue) {
  let docData = {
    last_updated: now,
    field: field,
    oldValue: oldValue,
    newValue: newValue,
  };

  let documentName = 'recipe_' + recipeName + '_' + field + "_" + now;

  try {
    setDoc(doc(database, "history", documentName), docData);
  } catch (e) {
    console.log("Error saving History:", e);
    return;
  }

  newHistoryList.push(documentName);
}

function ingrediantsEqual(newIngredients, oldIngredients) {
  if (newIngredients.length !== oldIngredients.length) return false;
  for (let ii = 0; ii < newIngredients.length; ii++) {
    if (newIngredients[ii] !== oldIngredients[ii]) return false;
  }
  return true;
}

export const recordHistory = async (newHistoryList, now, newRecipe, docRef) => {
  let existingDoc = "";
  try {
    let oldDocRef = doc(database, COLLECTION, docRef);
    existingDoc = await getDoc(oldDocRef);
  } catch (e) {
    console.log("Error getting existing Recipe for History:", e);
  }

  try {
    if (newRecipe.name !== existingDoc.data().name) {
      addHistory(newHistoryList, now, docRef, "name", existingDoc.data().name, newRecipe.name);
    }
    if (newRecipe.cost_per_item !== existingDoc.data().cost_per_item) {
      addHistory(newHistoryList, now, docRef, "cost_per_item", roundToDollars(existingDoc.data().cost_per_item), roundToDollars(newRecipe.cost_per_item));
    }
    if (newRecipe.hours_taken !== existingDoc.data().hours_taken) {
      addHistory(newHistoryList, now, docRef, "hours_taken", existingDoc.data().hours_taken, newRecipe.hours_taken);
    }
    if (newRecipe.makes_qty !== existingDoc.data().makes_qty) {
      addHistory(newHistoryList, now, docRef, "makes_qty", existingDoc.data().makes_qty, newRecipe.makes_qty);
    }
    if (existingDoc.data().note && newRecipe.note !== existingDoc.data().note) {

      addHistory(newHistoryList, now, docRef, "note", existingDoc.data().note, newRecipe.note);
    }
    if (newRecipe.cost_per_item_high !== existingDoc.data().cost_per_item_high) {
      addHistory(newHistoryList, now, docRef, "cost_per_item_high", roundToDollars(existingDoc.data().cost_per_item_high), roundToDollars(newRecipe.cost_per_item_high));
    }
    if (newRecipe.cost_per_item_low !== existingDoc.data().cost_per_item_low) {
      addHistory(newHistoryList, now, docRef, "cost_per_item_low", roundToDollars(existingDoc.data().cost_per_item_low), roundToDollars(newRecipe.cost_per_item_low));
    }
    if (newRecipe.total_batch_cost_high !== existingDoc.data().total_batch_cost_high) {
      addHistory(newHistoryList, now, docRef, "total_batch_cost_high", roundToDollars(existingDoc.data().total_batch_cost_high), roundToDollars(newRecipe.total_batch_cost_high));
    }
    if (newRecipe.total_batch_cost_low !== existingDoc.data().total_batch_cost_low) {
      addHistory(newHistoryList, now, docRef, "total_batch_cost_low", roundToDollars(existingDoc.data().total_batch_cost_low), roundToDollars(newRecipe.total_batch_cost_low));
    }
    if (newRecipe.total_ingredient_cost !== existingDoc.data().total_ingredient_cost) {
      addHistory(newHistoryList, now, docRef, "total_ingredient_cost", roundToDollars(existingDoc.data().total_ingredient_cost), roundToDollars(newRecipe.total_ingredient_cost));
    }
    if (newRecipe.total_high_profit !== existingDoc.data().total_high_profit) {
      addHistory(newHistoryList, now, docRef, "total_high_profit", roundToDollars(existingDoc.data().total_high_profit), roundToDollars(newRecipe.total_high_profit));
    }
    if (newRecipe.total_low_profit !== existingDoc.data().total_low_profit) {
      addHistory(newHistoryList, now, docRef, "total_low_profit", roundToDollars(existingDoc.data().total_low_profit), roundToDollars(newRecipe.total_low_profit));
    }
    if (newRecipe.low_profit_index !== existingDoc.data().low_profit_index) {
      addHistory(newHistoryList, now, docRef, "low_profit_index", roundToDollars(existingDoc.data().low_profit_index), roundToDollars(newRecipe.low_profit_index));
    }
    if (!ingrediantsEqual(newRecipe.ingredients, existingDoc.data().ingredients)) {
      addHistory(newHistoryList, now, docRef, "ingredients", existingDoc.data().ingredients, newRecipe.ingredients);
    }
  } catch (e) {
    console.log("Error adding history for:" + existingDoc.id);
  }
}
/**
 * ------------ End History ----------------
 */
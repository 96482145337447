import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { roundToDollars } from '../utils/MoneyUtils';
import { Timestamp } from "firebase/firestore";

import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Stack,
} from "react-bootstrap";

import {
  makeIngredientMap,
  recipeNameExists,
  recordHistory,
  loadHistoryMap,
  Recipe,
  documentToRecipe,
  getRecipe,
  recalculateCosts,
  saveRecipe,
  default_hourly_rate,
} from '../data/RecipeData';

import { getIngredientMapFromDB } from '../data/IngredientData';

const default_low_profit_index = 1.4;

export class RecipeForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ingredientMap: new Map(),
      recipeIngredientMap: new Map(),
      recipeName: "",
      docRef: "",
      recipe: new Recipe(),
      validated: false,
      isOpen: false,
      showForm: false,
      modalString: "",
      chosenIngredient: "",
      chosenQuantity: "",
      deleteDocRef: "",
      deleteDocDescription: "",
      isDeleteIngredientConfirmOpen: false,
      historyMap: new Map(),
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeChosenIngredientValue = this.changeChosenIngredientValue.bind(this);
    this.handleClose = this.handleClose(this);
    this.COLLECTION = "recipes";
  }

  handleClose = () => this.setState({ isOpen: false });

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  addIngredient = () => {
    /**
     * First remove the ingredient if it exists.
     */
    let cleanedIngredients = this.state.recipe.ingredients.filter(ingredient => !ingredient.startsWith(this.state.chosenIngredient));
    cleanedIngredients.push(this.state.chosenIngredient + ":" + this.state.chosenQuantity);

    let updatedRecipe = this.state.recipe;
    updatedRecipe.ingredients = cleanedIngredients;

    this.setState({
      showForm: !this.state.showForm,
      chosenIngredient: "",
      chosenQuantity: "",
      recipe: recalculateCosts(updatedRecipe, this.state.ingredientMap),
    });
  }

  toggleDeleteModal = (ingredientDocRef, description) => {
    this.setState({
      deleteDocRef: ingredientDocRef,
      deleteDocDescription: description,
      isDeleteIngredientConfirmOpen: !this.state.isDeleteIngredientConfirmOpen,
    });
  }

  handleDelete = async (ingredientDocRef) => {
    let cleanedIngredients = new Array();
    cleanedIngredients = this.state.recipe.ingredients.filter(ingredient => ingredient.indexOf(ingredientDocRef) === -1);

    let updatedRecipe = this.state.recipe;
    updatedRecipe.ingredients = cleanedIngredients;

    this.setState({
      chosenIngredient: "",
      chosenQuantity: "",
      isDeleteIngredientConfirmOpen: !this.state.isDeleteIngredientConfirmOpen,
      recipe: recalculateCosts(updatedRecipe, this.state.ingredientMap),
    });
  }


  async getExistingDocument() {
    /**
     * first get the recipe data.
     */
    let existingRecipeDoc = await getRecipe(this.props.docRef);
    console.log("existingRecipeDoc loaded: " + existingRecipeDoc.data().name);
    let documentRef = this.props.docRef;
    let recipeName = existingRecipeDoc.data().name;
    let newHistoryMap = new Map();

    let existingRecipe = documentToRecipe(existingRecipeDoc);

    if (this.props.mode === 'Copy') {
      documentRef = '';
      existingRecipe.history = new Array();
    } else {
      if (existingRecipe.history && existingRecipe.history.length !== 0) {
        newHistoryMap = await loadHistoryMap(existingRecipe.history);
      }
    }


    let low_profit_index = existingRecipe.low_profit_index;
    if (!low_profit_index) {
      existingRecipe.low_profit_index = default_low_profit_index;
    }
    if (!existingRecipe.note) {
      existingRecipe.note = "";
    }

    this.setState({
      recipeName: recipeName,
      docRef: documentRef,
      recipe: existingRecipe,
      historyMap: newHistoryMap,
    }, () => {
      recalculateCosts(existingRecipe, this.state.ingredientMap);
    });

  }
  async loadData() {
    let tempIngrediantMap = await getIngredientMapFromDB();
    this.setState({
      ingredientMap: tempIngrediantMap,
    }, async () => {
      if (this.props.docRef !== '') {
        await this.getExistingDocument();
      }
    });
  }

  componentDidMount() {
    this.loadData();
  }

  changeChosenIngredientValue(event) {
    if (event.target.id === 'ingredient') {
      this.setState({ chosenIngredient: event.target.value });
    } else if (event.target.id === 'quantity') {
      this.setState({ chosenQuantity: event.target.value });
    }
  }

  changeValue(event) {
    if (event.target.id === 'name') {
      let tempRecipe = this.state.recipe;
      tempRecipe.name = event.target.value;
      this.setState({ recipe: tempRecipe });
    } if (event.target.id === 'note') {
      let tempRecipe = this.state.recipe;
      tempRecipe.note = event.target.value;
      this.setState({ recipe: tempRecipe });
    } else if (event.target.id === 'makes_qty') {
      let tempRecipe = this.state.recipe;
      tempRecipe.makes_qty = event.target.value;
      let recalculatedRecipe = recalculateCosts(tempRecipe, this.state.ingredientMap);
      this.setState({ recipe: recalculatedRecipe });
    } else if (event.target.id === 'hours_taken') {
      let tempRecipe = this.state.recipe;
      tempRecipe.hours_taken = event.target.value;
      let recalculatedRecipe = recalculateCosts(tempRecipe, this.state.ingredientMap);
      this.setState({ recipe: recalculatedRecipe });
    } else if (event.target.id === 'low_profit_index') {
      let tempRecipe = this.state.recipe;
      tempRecipe.low_profit_index = event.target.value;
      let recalculatedRecipe = recalculateCosts(tempRecipe, this.state.ingredientMap);
      this.setState({ recipe: recalculatedRecipe });
    } else if (event.target.id === 'last_update') {
      this.setState({ last_update: event.target.value });
    }
  }

  getNewRecipeReference(name) {
    const regex2 = /[^A-Za-z0-9_]/ig;
    return name.replaceAll(regex2, '_');
  }

  saveChangedRecipe = async (event) => {
    event.preventDefault();

    /**
     * Do form validation.
     */
    const form = event.currentTarget;
    let documentReference = "";
    if (this.state.recipe.name !== '' && this.props.mode === 'Copy') {
      documentReference = this.getNewRecipeReference(this.state.recipe.name);
      let nameElement = document.getElementById('name');
      nameElement.setCustomValidity('');
      let exists = await recipeNameExists(documentReference);
      if (exists) {
        let nameElement = document.getElementById('name');
        nameElement.setCustomValidity("A recipe with this name already exists");
      }
    } else {
      documentReference = this.state.recipe.document_reference;
    }

    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      form.reportValidity();
      return;
    }

    let newHistoryList = new Array();
    let newHistoryMap = new Map();
    let now = Timestamp.now();

   if (this.props.mode === 'Edit') {
      if (this.state.recipe.history && this.state.recipe.history.length !== 0) {
        newHistoryList = this.state.recipe.history;
      }
      await recordHistory(newHistoryList, now, this.state.recipe, documentReference);
    }

    let recipe = recalculateCosts(this.state.recipe, this.state.ingredientMap);

    let docData = {
      name: recipe.name,
      hours_taken: recipe.hours_taken,
      makes_qty: recipe.makes_qty,
      note: recipe.note,
      total_ingredient_cost: recipe.total_ingredient_cost,
      total_batch_cost_high: recipe.total_batch_cost_high,
      total_batch_cost_low: recipe.total_batch_cost_low,
      cost_per_item_high: recipe.cost_per_item_high,
      cost_per_item_low: recipe.cost_per_item_low,
      low_profit_index: recipe.low_profit_index,
      ingredients: recipe.ingredients,
      last_update: now,
      history: newHistoryList,
    };

    saveRecipe(documentReference, docData);

    this.setState({
      docRef: "",
      recipe: new Recipe(),
      validated: false,
      isOpen: true,
      modalString: "Recipe added!",
      deleteDocRef: "",
      deleteDocDescription: "",
      historyMap: newHistoryMap,
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  renderHistoryTableRow = (historyDocRef, historyRecord) => {
    let updatedDate = historyRecord.updateDate.toDate().toString();
    let oldValue = historyRecord.oldValue;
    let newValue = historyRecord.newValue;
    if(historyRecord.field === 'ingredients') {
      let oldValueString = '';
      for (let ii = 0; ii < oldValue.length; ii++) {
        let parts = oldValue[ii].split(":");
        let ingredient = parts[0].trim();
        let quantity = parts[1].trim();
        oldValueString = oldValueString + '* ' + ingredient + " - qty: " + quantity + '\n';
      }
      oldValue = oldValueString;
   
      let newValueString = '';
      for (let ii = 0; ii < newValue.length; ii++) {
        let parts = newValue[ii].split(":");
        let ingredient = parts[0];
        let quantity = parts[1];
        newValueString = newValueString + '* ' + ingredient + " - qty: " + quantity + '\n';
      }
      newValue = newValueString;
    }
    return (
      <tr>
        <td className='history_cell'>{updatedDate}</td>
        <td className='history_cell'>{historyRecord.field}</td>
        <td><div class="scrollable-cell">{oldValue}</div></td>
        <td><div class="scrollable-cell">{newValue}</div></td>
      </tr>
    );
  }

  renderIngredientTableRow = (ingredientDocRef, doc) => {

    return (
      <tr>
        <td>{doc.description}</td>
        <td>{roundToDollars(doc.cost)}</td>
        <td>{doc.per}</td>
        <td>{doc.quantity}</td>
        <td>{roundToDollars(doc.total_cost)}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.toggleDeleteModal(ingredientDocRef, doc.description)
              }}>
              Delete
            </Button>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenIngredient: ingredientDocRef,
                  chosenQuantity: doc.quantity,
                }, () => {
                  this.toggleShowForm();
                });
              }}>
              Edit
            </Button>
          </Stack>
        </td>
      </tr>
    );

  }

  getIngredientSelect = (ingredientDocRef, doc) => {
    return (
      <>
        <option value={ingredientDocRef}>{doc.description + '- Cost:' + roundToDollars(doc.cost) + '- Per:' + doc.per}</option>
      </>
    );
  }

  render() {

    let recipeIngredientMap = makeIngredientMap(this.state.recipe.ingredients, this.state.ingredientMap);
    let ingredientMap = this.state.ingredientMap;
    let historyMap = this.state.historyMap;
    let lastUpdatedString = "";
    if (this.state.recipe.last_update) {
      lastUpdatedString = this.state.recipe.last_update.toDate().toString();
    }

    return (
      <>
        {
          /* 
          * Recipe Add
          */
        }
            <Container fluid>
            <Modal show={this.state.showForm} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Form onSubmit={this.saveChangedRecipe}> */}
                <Form>
                  <Form.Group as={Col}>
                    <Form.Label as={Col} htmlFor="ingredient">Ingredient:</Form.Label>
                    <Form.Select
                      required
                      value={this.state.chosenIngredient}
                      id="ingredient"
                      aria-label="Default select example"
                      onChange={this.changeChosenIngredientValue}
                    >
                      <option></option>
                      {
                        [...ingredientMap].map(([key, value]) => {
                          return this.getIngredientSelect(key, value);
                        }
                        )
                      }
                    </Form.Select>
                  </Form.Group>
                  <Form.Label as={Col} htmlFor="quantity">Qty:</Form.Label>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Control
                      value={this.state.chosenQuantity}
                      required
                      placeholder="0"
                      id="quantity"
                      onChange={this.changeChosenIngredientValue}
                    />
                  </Form.Group>
                </Form>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={this.toggleShowForm}>
                  Cancel
                </Button>{' '}
                <Button variant="secondary" size="sm" onClick={this.addIngredient}>
                  Save Ingredient
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.isDeleteIngredientConfirmOpen}>
              <Container>
                <Row>
                  Delete {this.state.deleteDocDescription}?
                </Row>
                <Row>
                  <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{'   '}
                  <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
                </Row>
              </Container>
            </Modal>
            <h1>Recipe {this.props.mode} "{this.state.recipeName}"</h1>
            <Form noValidate validated={this.state.validated} onSubmit={this.saveChangedRecipe}>
              <Button variant="dark" size="sm" onClick={() => { this.props.toggleForm(); }}>
                Cancel
              </Button>{' '}
              <Button
                size="sm"
                disabled={this.props.mode === 'View'}
                variant="dark"
                type="submit">
                Save
              </Button>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="name">Name:</Form.Label>
                    <Form.Control
                      disabled={this.props.mode === 'View'}
                      required
                      value={this.state.recipe.name}
                      placeholder="Name"
                      id="name"
                      onChange={this.changeValue}
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="hours_taken">Hours Taken:</Form.Label>
                    <Form.Label as={Col} htmlFor="makes_qty">Makes:</Form.Label>
                    <Form.Label as={Col} htmlFor="low_profit_index">Lower Profit Index:</Form.Label>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Control
                        disabled={this.props.mode === 'View'}
                        required
                        value={this.state.recipe.hours_taken}
                        placeholder="0"
                        id="hours_taken"
                        onChange={this.changeValue}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Control
                        disabled={this.props.mode === 'View'}
                        value={this.state.recipe.makes_qty}
                        placeholder="0"
                        id="makes_qty"
                        onChange={this.changeValue}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Select className="mb-3"
                        disabled={this.props.mode === 'View'}
                        required
                        value={this.state.recipe.low_profit_index}
                        id="low_profit_index"
                        onChange={this.changeValue}
                      >
                        <option value='1.6'>1.6 (60% profit)</option>
                        <option value='1.5'>1.5 (50% profit)</option>
                        <option value='1.4'>1.4 (40% profit)</option>
                        <option value='1.3'>1.3 (30% profit)</option>
                        <option value='1.2'>1.2 (20% profit)</option>
                        <option value='1.1'>1.1 (10% profit)</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>
                <Col>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="total_ingredient_cost">Total Ingredient Cost:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(this.state.recipe.total_ingredient_cost)}
                        aria-label="totalingredientcost"
                        placeholder="0.00"
                        id="total_ingredient_cost"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="cost_per_item_high">Cost per Item High Profit:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(this.state.recipe.cost_per_item_high)}
                        aria-label="cost_per_item_high"
                        placeholder="0.00"
                        id="cost_per_item_high"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="cost_per_item_low">Cost per Item Low Profit:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(this.state.recipe.cost_per_item_low)}
                        aria-label="cost_per_item_low"
                        placeholder="0.00"
                        id="cost_per_item_low"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="total_batch_cost_high">Total Batch Cost High Profit:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(this.state.recipe.total_batch_cost_high)}
                        aria-label="total_batch_cost_high"
                        placeholder="0.00"
                        id="total_batch_cost_high"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="total_batch_cost_low">Total Batch Cost Low Profit:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(this.state.recipe.total_batch_cost_low)}
                        aria-label="total_batch_cost_low"
                        placeholder="0.00"
                        id="total_batch_cost_low"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                  <Row className="mb-3">
                    <Form.Label as={Col} htmlFor="total_batch_cost_low">Labor Cost:</Form.Label>
                    <InputGroup as={Col} className="mb-3">
                      <Form.Control
                        value={roundToDollars(default_hourly_rate * this.state.recipe.hours_taken)}
                        aria-label="total_batch_cost_low"
                        placeholder="0.00"
                        id="total_batch_cost_low"
                        disabled
                      />
                    </InputGroup>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Label as={Col} htmlFor="note">Notes:</Form.Label>
              </Row>
              <Row className="mb-3">
                <Form.Control
                  as="textarea"
                  disabled={this.props.mode === 'View'}
                  value={this.state.recipe.note}
                  placeholder="notes..."
                  id="note"
                  onChange={this.changeValue}
                />
              </Row>
              <Container className="mb-3">
                <Row>
                  <Col>
                    <Button
                      size="sm"
                      disabled={this.props.mode === 'View'}
                      variant="dark"
                      onClick={() => {
                        this.setState({
                          chosenIngredient: "",
                          chosenQuantity: "",
                        }, () => {
                          this.toggleShowForm()
                        });
                      }}>
                      Add Ingredient
                    </Button>
                  </Col>
                </Row>
              </Container>
              <Row className="mb-3">
                Ingredients:
              </Row>
              <Row className="mb-3">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Cost</th>
                      <th>Per</th>
                      <th>Quantity</th>
                      <th>Total Cost</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      [...recipeIngredientMap].map(([key, value]) => {
                        return this.renderIngredientTableRow(key, value);
                      }
                      )
                    }
                  </tbody>
                </Table>
              </Row>
              <Row className="mb-3">
                <Form.Label htmlFor="lastupdated">Last Updated:</Form.Label>
                <Form.Control
                  value={lastUpdatedString}
                  id="lastupdated"
                  disabled
                />
              </Row>
              <Button variant="dark" size="sm" onClick={() => { this.props.toggleForm(); }}>
                Cancel
              </Button>{' '}
              <Button
                size="sm"
                disabled={this.props.mode === 'View'}
                variant="dark"
                type="submit">
                Save
              </Button>
              <Row className="mb-3">
                <Form.Label htmlFor="history">History:</Form.Label>
                <Table 
                  disabled
                  id="history"
                  bordered>
                  <thead>
                    <tr>
                      <th>Update Date</th>
                      <th>Field</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      [...historyMap].map(([key, value]) => {
                        return this.renderHistoryTableRow(key, value);
                      }
                      )
                    }
                  </tbody>
                </Table>
              </Row>
            </Form>
          </Container>
      </>
    );

  }
}

import * as React from "react";
import "./../index.css";
import "./../fonts/Bakerie/BakerieSmoothCondensed-Black.otf";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Stack
} from "react-bootstrap";
import { getDocs, collection, query, orderBy, deleteDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';

import Modal from "react-modal";
import { EventForm } from './EventForm';
import { CustomizedTypeahead } from './CustomizedTypeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { getRecipeMapFromDB } from "../data/RecipeData";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


export class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipeMap: new Map(),
      allDocs: [],
      docRef: "",
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      previousRef: "",
      isDeleteConfirmOpen: false,
      deleteDocName: "",
      deleteDocRef: "",
      mode: "Add",
      showForm: false,
      dataChanged: false,
      currentlySelectedId: '',
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.setDataChanged = this.setDataChanged.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.COLLECTION = "events";
  }

  toggleShowForm = () => {
    this.setState(
      {
        showForm: !this.state.showForm,
        docMap: new Map(),
        dataChanged: false,
      },
      () => {
        this.readDocs();
      }
    );
  };

  setDataChanged = (changed) =>{
    this.setState({
      dataChanged: changed,
    });
  }

  toggleMode = (mode) => {
    this.setState({
      docRef: "",
      mode: mode,
      showForm: true,
      dataChanged: false,
    });
  };

  toggleDeleteModal = (docRef, name) => {

    this.setState({
      deleteDocRef: docRef,
      deleteDocName: name,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  };

  handleUpdate = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "Edit",
      dataChanged: false,
    });
  };

  handleView = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "View",
      dataChanged: false,
    });
  };
  async buildFindOptions() {
    let tempOptions = new Array();
    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));
      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        let option = { id: doc.id, value: doc.data().name };
        tempOptions.push(option);
      });
      this.setState({
        options: tempOptions,
      });
    } catch (e) {
      console.log("Error getting Events:", e);
    }
  }

  handleDelete = async (docRef) => {
    await deleteDoc(doc(database, this.COLLECTION, docRef));
    this.state.docMap.delete(docRef);
    this.buildFindOptions();
    this.setState({
      deleteDocRef: "",
      isDeleteConfirmOpen: false,
    });
  };

  async readDocs() {
    let allDocs = new Array();
    let tempOptions = new Array();
    this.setState({
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
    });

    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.docMap.set(doc.id, doc.data());
        this.state.refMap.set(doc.id, React.createRef());
        let option = { id: doc.id, value: doc.data().name };
        tempOptions.push(option);
        allDocs.push(doc);
      });
      this.setState({
        allDocs: allDocs,
        options: tempOptions,
      });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  async loadData() {
    this.readDocs();
    let recipeMap = await getRecipeMapFromDB();
    this.setState({
      recipeMap: recipeMap,
    })

  }

  componentDidMount() {
    this.loadData();
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (this.state.dataChanged) {
      e.preventDefault();
      e.returnValue = "";
    }
  }

  scrollToView(ref) {
    if (this.state.previousRef !== '' && this.state.previousRef.current) {
      let oldRef = this.state.previousRef;
      oldRef.current.style.background = 'white';
      this.setState({
        previousRef: "",
      })
    }
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      ref.current.style.background = 'gray';
      this.setState({
        previousRef: ref,
      })
    });
  }

  renderTableRow = (docRef, doc) => {
    let lastUpdatedString = "";
    if (doc.last_update) {
      lastUpdatedString = moment(doc.last_update.toDate()).format('MM/DD/YYYY HH:mm:ss');
    }
    let name = doc.name;

    return (
      <tr key={docRef} ref={this.state.refMap.get(docRef)} id={docRef}>
        <td>{doc.name}</td>
        <td>{doc.description}</td>
        <td>{doc.location}</td>
        <td>{doc.date}</td>
        <td>{doc.time}</td>
        <td>{lastUpdatedString}</td>
        <td>
          <DropdownButton variant="dark" id="dropdown-basic-button" title="Actions">
            <Dropdown.Item variant="dark" onClick={() => { this.handleView(docRef) }}>View</Dropdown.Item>
            <Dropdown.Item variant="dark" onClick={() => { this.handleUpdate(docRef) }}>Edit</Dropdown.Item>
            <Dropdown.Item variant="dark" onClick={() => { this.toggleDeleteModal(docRef, name) }}>Delete</Dropdown.Item>
          </DropdownButton>
        </td>
      </tr>
    );
  };

  render() {
    let docMap = this.state.docMap;

    return (
      <>
        {
          /* 
          * Product List
          */
        }
        <Stack gap={3}>
          {this.state.showForm &&
            <EventForm
              recipeMap={this.state.recipeMap}
              docRef={this.state.docRef}
              mode={this.state.mode}
              toggleForm={() => {
                this.toggleShowForm();
              }}
              setDataChanged={(changed) =>{this.setDataChanged(changed)}}
            />
          }
          {!this.state.showForm &&
            <Container fluid>
              <Row className="justify-content-md-left">
                <Col>
                  <h2>Events:</h2>
                </Col>
                <Col>
                  <Button variant="dark" onClick={() => { this.toggleMode('Add') }}>Add New Event</Button>
                </Col>
                <Col md="auto">
                  Find:
                </Col>
                <Col md="auto">
                  <CustomizedTypeahead
                    onChange={(selected) => {
                      console.log("selected" + selected);
                      if (selected.length > 0) {
                        let ref = this.state.refMap.get(selected[0].id);
                        this.scrollToView(ref);
                      }
                    }}
                    align='left'
                    labelKey={'value'}
                    options={this.state.options}
                  />
                </Col>
              </Row>
            </Container>
          }
          <Modal
            className="modalDialog"
            isOpen={this.state.isDeleteConfirmOpen}
            onRequestClose={this.toggleModal}
            contentLabel="My dialog"
          >
            <div>Delete {this.state.deleteDocName}?</div>
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.toggleDeleteModal();
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.handleDelete(this.state.deleteDocRef);
              }}
            >
              Confirm
            </Button>
          </Modal>
          {!this.state.showForm && (
            <div className="scrollingTable">
              <Table bordered hover>
                <thead>
                  <tr className='header'>
                    <th className='table-header'>Name</th>
                    <th className='table-header'>Description</th>
                    <th className='table-header'>Location</th>
                    <th className='table-header'>Date</th>
                    <th className='table-header'>Time</th>
                    <th className='table-header'>Last Update</th>
                    <th className='table-header'></th>
                  </tr>
                </thead>
                <tbody>
                  {[...docMap].map(([key, value]) => {
                    return this.renderTableRow(key, value);
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Stack>
      </>
    );
  }
}
